import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { Status } from "../../../types";
import {
  ActivityStatus,
  useActivityStatusesUpdateMutation,
} from "../../../api/generated/graphql";
import { activityUpdated } from "../../../typewriter/segment";
import { ChoiceChips, Option } from "../choiceChips/ChoiceChips";

type Props = {
  status: ActivityStatus;
  activityId: string;
  isDue: boolean;
  visitInstanceId: string;
  visitStatus: Status;
};

export const ActivityStatusButtons = ({
  status,
  activityId,
  isDue,
  visitInstanceId,
  visitStatus,
}: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [actityStatusesUpdate, { loading }] = useActivityStatusesUpdateMutation(
    {
      refetchQueries: ["VisitById"],
    },
  );

  const handleStatusUpdate = (
    newStatus: ActivityStatus,
    comment: string | null,
  ) => {
    toast.promise(
      actityStatusesUpdate({
        variables: {
          visitInstanceId,
          input: [
            {
              activityId: activityId,
              status: newStatus,
              comment,
            },
          ],
        },
      }),
      {
        loading: t("toast.activityStatusUpdating"),
        success: t("toast.activityStatusUpdated"),
        error: t("toast.activityStatusUpdateFailed"),
      },
    );

    const activityCompleted = newStatus === ActivityStatus.Completed;

    activityUpdated({
      activity_completed: activityCompleted,
      not_completed_reason: `${comment}`,
    });
  };

  const onNotCompleted = () => {
    navigate(`/visit/${visitInstanceId}/${activityId}/reason`);
    return;
  };

  const options: Option[] = [
    ...(isDue
      ? [
          {
            label: t("visitDetails.notCompleted"),
            value: ActivityStatus.CouldNotComplete,
            action: () =>
              status === ActivityStatus.CouldNotComplete
                ? handleStatusUpdate(ActivityStatus.NotStarted, null)
                : onNotCompleted(),
          },
        ]
      : []),
    {
      label: t("visitDetails.completed"),
      value: ActivityStatus.Completed,
      action: () =>
        handleStatusUpdate(
          status === ActivityStatus.Completed
            ? ActivityStatus.NotStarted
            : ActivityStatus.Completed,
          null,
        ),
    },
  ];

  return (
    <div className="flex grow flex-row items-center justify-center gap-2 text-title">
      <ChoiceChips
        options={options}
        selectedOption={status}
        loading={loading}
        disabled={loading || visitStatus === "clockedOut"}
      />
    </div>
  );
};
