import classNames from "classnames";
import { forwardRef } from "react";
import { LoadingBounce } from "../../loading/index";
import { match } from "ts-pattern";

type ChoiceChipButtonProps = {
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  text?: string;
  className?: string;
  loading?: boolean;
  ariaLabel?: string;
  icon?: React.ElementType;
};

export const ChoiceChipButton = forwardRef<
  HTMLButtonElement,
  ChoiceChipButtonProps
>(function Button(
  { id, onClick, text, className, loading = false, ariaLabel, icon: Icon },
  ref,
) {
  const onlyIcon = Icon ? true : false;
  const commonClassNames =
    "flex items-center justify-center focus:outline-none";
  const sizeClassNames = "text-base leading-normal";

  return (
    <button
      id={id}
      ref={ref}
      className={classNames(commonClassNames, sizeClassNames, className, {
        // Regular text button
        "rounded-xl py-3 px-5 font-medium": !onlyIcon,
        // Circle icon button
        "rounded-2xl p-2 w-8 h-8": onlyIcon,
      })}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {match({ onlyIcon, text, loading })
        .with({ loading: true }, () => (
          <LoadingBounce className="text-primary-600" />
        ))
        .with({ onlyIcon: true }, () => (Icon ? <Icon /> : undefined))
        .with({ onlyIcon: false, text }, () => (
          <span
            className={classNames(
              { "text-transparent": loading },
              "whitespace-pre",
            )}
          >
            {text}
          </span>
        ))
        .otherwise(() => null)}
    </button>
  );
});

type Choice = {
  id: string;
  text?: string;
  icon?: React.ElementType;
  variant?: "default" | "completed" | "notCompleted";
};

type ChoiceChipsProps = {
  choices: Choice[];
  selectedChoice?: Choice;
  onClick: (choice: Choice) => void;
  loading?: boolean;
};

export const ChoiceChips = forwardRef<HTMLDivElement, ChoiceChipsProps>(
  function ChoiceChips({ choices, selectedChoice, onClick, loading }, ref) {
    return (
      <div ref={ref} className="flex flex-row gap-1">
        {choices.map((choice) => {
          const variant = choice.variant ?? "default";
          return (
            <ChoiceChipButton
              key={choice.id}
              className={classNames("my-1 basis-1/4", {
                // Option is selected
                "text-greyscale-900": selectedChoice
                  ? choice.id === selectedChoice.id
                  : false,

                // Option is selected with default or no variant
                "bg-primary-300 hover:bg-primary-400 dark:bg-primary-400 dark:hover:bg-primary-300":
                  (selectedChoice ? choice.id === selectedChoice.id : false) &&
                  variant === "default",

                // Option is selected with complete variant
                "bg-secondary-300 hover:bg-secondary-400 dark:bg-secondary-500 dark:hover:bg-secondary-400":
                  (selectedChoice ? choice.id === selectedChoice.id : false) &&
                  variant === "completed",

                // Option is selected with not complete variant
                "bg-critical-300 hover:bg-critical-400 dark:bg-critical-800 dark:hover:bg-critical-600":
                  (selectedChoice ? choice.id === selectedChoice.id : false) &&
                  variant === "notCompleted",

                // Option is not selected
                "bg-greyscale-200 hover:bg-greyscale-300 dark:bg-greyscale-700 dark:hover:bg-greysclae-600 dark:text-greyscale-100 text-greyscale-900":
                  selectedChoice ? choice.id !== selectedChoice.id : true,
              })}
              text={choice.text}
              icon={choice.icon}
              onClick={() => onClick(choice)}
              loading={loading}
            />
          );
        })}
      </div>
    );
  },
);
