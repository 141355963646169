import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useViewerQuery } from "./api/generated/graphql";
import { useCareContext, UserActions } from "./providers";
import {
  NotificationProvider,
  NotificationsPrompt,
} from "@frontend/lyng/notifications";
import { Splash } from "@frontend/lyng/common/Splash";
import { analytics } from "./analytics/analytics";
import { router } from "./router/Router";
import { PWAInstall } from "./components/core/PWAInstall/PWAInstall";
import { ApolloError } from "@apollo/client";
import { useNotificationsEnablePrompt } from "./hooks/notificationsPrompt";
import { useTranslate } from "@tolgee/react";
import { isCaregiver } from "./utils/viewerUtils";
import { usePWAInstallContext } from "./providers/PWAInstallProvider";
import { match } from "ts-pattern";
import segment from "./typewriter/segment";
import { isMobile } from "react-device-detect";
import { useFeatureFlag } from "./providers/FeatureFlags";
import { ChatWrapper } from "@frontend/lyng/comms";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  environment: import.meta.env.VITE_TIER,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const pwaWorkerAvailable = "serviceWorker" in navigator;

const AppRoot = () => {
  const { state, dispatch } = useCareContext();
  const { data, error } = useViewerQuery();
  const { isAppInstalled } = usePWAInstallContext();
  const { t } = useTranslate();
  const {
    shouldShowNotificationsPrompt,
    setShouldShowNotificationsPrompt,
    setAccepted,
  } = useNotificationsEnablePrompt();

  const ff_messaging = useFeatureFlag("Messaging");

  const dismissNotificationsPrompt = () => {
    setShouldShowNotificationsPrompt(false);
  };

  useEffect(() => {
    const viewer = data?.viewer;

    if (viewer && !state.viewer) {
      Sentry.setUser({
        id: viewer.id,
      });

      const activeTenant =
        viewer.tenants.find((tenant) => tenant.active) ?? viewer.tenants[0];
      if (!activeTenant) {
        // if no active tenant, set an error
        const error = new ApolloError({
          errorMessage: "No active tenant found",
        });
        dispatch(UserActions.setViewerError(error));

        Sentry.captureException(error);
        return;
      }

      dispatch(
        UserActions.setViewer({
          viewer,
          tenant: activeTenant,
        }),
      );
      localStorage.setItem("tenantId", activeTenant.id);

      analytics.identify(viewer.id, {
        tenantId: activeTenant?.id,
        tenantName: activeTenant?.name,
      });
    }

    if (error) dispatch(UserActions.setViewerError(error));
  }, [dispatch, data, error, state.viewer]);

  if (!state.viewer) {
    return <Splash />;
  }

  const subscriptionData = data?.viewer?.novuSubscriberIds?.mobile;
  const allowNotifications = isCaregiver(data?.viewer);

  const showNotificationPrompt = match({
    shouldShowNotificationsPrompt,
    pwaWorkerAvailable,
    allowNotifications,
    isAppInstalled,
    isMobile,
  })
    .with({ isMobile: true, isAppInstalled: false }, () => false)
    .with({ shouldShowNotificationsPrompt: false }, () => false)
    .with({ pwaWorkerAvailable: false }, () => false)
    .with({ allowNotifications: false }, () => false)
    .otherwise(() => true);

  return ff_messaging ? (
    <ChatWrapper
      loadingView={<Splash />}
      viewer={state.viewer}
      nameOrder={state.viewer.tenantSettings.nameOrder}
      country={state.viewer.tenantSettings.country}
      darkMode={true}
    >
      <NotificationProvider
        key={state.selectedTenant?.id}
        subscriptionData={subscriptionData}
        active={allowNotifications}
        feedId="mobile"
        trackingFunctions={{
          pushNotificationEnabled: () => {
            segment.pushNotificationsEnabled({});
          },
          notificationsAllRead: () => {
            segment.notificationsAllMarkedRead({});
          },
          notificationClicked: (count, type) => {
            segment.notificationClicked({ Count: count, Type: type });
          },
        }}
      >
        {showNotificationPrompt && (
          <NotificationsPrompt
            vapidKey={data?.viewer.vapidKey}
            dismiss={dismissNotificationsPrompt}
            registered={() => {
              setAccepted(true);
              setShouldShowNotificationsPrompt(false);
            }}
            t={t}
          />
        )}
        <RouterProvider router={router()} />
      </NotificationProvider>
      <PWAInstall />
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
    </ChatWrapper>
  ) : (
    <>
      <NotificationProvider
        key={state.selectedTenant?.id}
        subscriptionData={subscriptionData}
        active={allowNotifications}
        feedId="mobile"
        trackingFunctions={{
          pushNotificationEnabled: () => {
            segment.pushNotificationsEnabled({});
          },
          notificationsAllRead: () => {
            segment.notificationsAllMarkedRead({});
          },
          notificationClicked: (count, type) => {
            segment.notificationClicked({ Count: count, Type: type });
          },
        }}
      >
        {showNotificationPrompt && (
          <NotificationsPrompt
            vapidKey={data?.viewer.vapidKey}
            dismiss={dismissNotificationsPrompt}
            registered={() => {
              setAccepted(true);
              setShouldShowNotificationsPrompt(false);
            }}
            t={t}
          />
        )}
        <RouterProvider router={router()} />
      </NotificationProvider>
      <PWAInstall />
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
    </>
  );
};

export default AppRoot;
