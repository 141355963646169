import classNames from "classnames";
import { useRef } from "react";
import {
  ChannelPreviewUIComponentProps,
  MessageDeliveryStatus,
  StreamMessage,
} from "stream-chat-react";
import { Checkmark } from "../assets/icons/16/outline";
import { Paragraph } from "../typography";
import { Avatar } from "../avatar";
import { useUserByIdQuery } from "../api/generated/graphql";

const getLastMessageTime = (lastMessage?: StreamMessage) => {
  if (!lastMessage) return null;

  if (lastMessage.created_at) {
    const date =
      typeof lastMessage.created_at === "string"
        ? new Date(lastMessage.created_at)
        : (lastMessage.created_at as Date);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
    });
  }

  return null;
};

type MessageDeliveryStatusIndicator = {
  messageDeliveryStatus: MessageDeliveryStatus;
};

const MessageDeliveryStatusIndicator = ({
  messageDeliveryStatus,
}: MessageDeliveryStatusIndicator) => {
  // the last message is not an own message in the channel
  if (!messageDeliveryStatus) return null;

  return (
    <div>
      {messageDeliveryStatus === MessageDeliveryStatus.DELIVERED && (
        <Checkmark className="text-greyscale-400" />
      )}
      {messageDeliveryStatus === MessageDeliveryStatus.READ && (
        <div className="text-primary-500 flex flex-row">
          <Checkmark />
          <Checkmark className="-ml-5" />
        </div>
      )}
    </div>
  );
};

// Gets user id for the latest message on the channel
const getUserIdFromChannel = (
  channel: ChannelPreviewUIComponentProps["channel"],
) => {
  if (channel.state.messages.length === 0) return "";

  return (
    channel.state.messages[channel.state.messages.length - 1].user?.id ?? ""
  );
};

export const CommsChannelPreview = (props: ChannelPreviewUIComponentProps) => {
  const {
    channel,
    className: customClassName = "",
    displayTitle,
    latestMessagePreview,
    lastMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    watchers,
    unread,
  } = props;

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  const ownId = channel._client.userID;
  const otherMemberIds = Object.keys(channel.state.members).filter(
    (member) => member !== ownId,
  );
  const otherUserId = otherMemberIds[0] ?? getUserIdFromChannel(channel);

  const { data } = useUserByIdQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: otherUserId ?? "" },
  });

  const firstName = data?.userById.firstName;
  const lastName = data?.userById.lastName;

  const title =
    channel.data?.member_count && channel.data.member_count > 2
      ? displayTitle
      : `${firstName} ${lastName}`;

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  return (
    <div className="flex flex-col w-full bg-transparent">
      <button
        className={classNames(
          `flex h-16 mx-4 py-4  cursor-pointer `,
          {
            "basis-0 bg-white dark:bg-greyscale-800 rounded-2xl shadow-[0_2px_6px_-1px_rgba(0,0,0,0.1)] flex-row hover:shadow-[0_2px_6px_-1px_rgba(0,0,0,0.2)] ":
              unread,
          },
          { "dark:border-greyscale-700 border-b": !unread },
          customClassName,
        )}
        data-testid="channel-preview-button"
        onClick={onSelectChannel}
        ref={channelPreviewButton}
      >
        <div className="px-2 flex flex-grow content-center">
          <Avatar
            firstName={firstName ?? ""}
            lastName={lastName ?? ""}
            randomizeColor
          />
        </div>
        <div className="flex flex-grow w-full">
          <div className="flex flex-col justify-start items-start w-full">
            <div className="text-greyscale-100">
              <Paragraph
                size="s"
                className="text-greyscale-100 font-poppins truncate"
              >
                {title}
              </Paragraph>
            </div>
            <div className="text-greyscale-400 text-xs font-inclusive font-normal flex flex-row justify-between items-center w-full">
              <div className="truncate flex-1 text-ellipsis w-4 text-start whitespace-nowrap overflow-hidden">
                {latestMessagePreview}
              </div>
              {lastMessage && (
                <div className="flex flex-row flex-end flex-shrink-0 justify-end items-end text-xs px-2 font-inclusive whitespace-nowrap">
                  <div className="px-1">
                    <MessageDeliveryStatusIndicator
                      messageDeliveryStatus={
                        lastMessage?.unread
                          ? MessageDeliveryStatus.DELIVERED
                          : MessageDeliveryStatus.READ
                      }
                    />
                  </div>
                  {getLastMessageTime(lastMessage)}
                </div>
              )}
            </div>
          </div>
        </div>
      </button>
    </div>
  );
};
