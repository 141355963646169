import { P, match } from "ts-pattern";
import { PillColors, PillProps } from "./types";
import { Label } from "../typography";
import classNames from "classnames";

const renderMaybeIconOrDot = (
  Icon: PillProps["icon"],
  side: "left" | "right",
  pillColor: PillColors,
  iconOnly: boolean,
) => {
  if (!Icon) {
    return null;
  }
  const iconColor = match(pillColor)
    .with("green", () => "text-greyscale-900")
    .with("yellow", () => "text-greyscale-900")
    .with("yellowSubdued", () => "text-greyscale-900")
    .with("white", () => "text-greyscale-900")
    .with("none", () => "text-greyscale-900")
    .with("red", () => "text-greyscale-900")
    .with("blue", () => "text-primary-600")
    .with("grey", () => "text-greyscale-600")
    .with("greySubdued", () => "text-greyscale-600")
    .with("darkBlue", () => "text-greyscale-0")
    .with("redSubdued", () => "text-greyscale-900")
    .exhaustive();

  return (
    <span
      className={classNames(iconColor, {
        "mr-2": side === "left" && !iconOnly,
        "ml-2": side === "right" && !iconOnly,
      })}
    >
      {Icon}
    </span>
  );
};

export const Pill = ({
  text,
  color,
  icon,
  iconSide = "left",
  className,
  size = "sm",
}: PillProps) => {
  const paddingClass = match([size, text, icon, iconSide])
    .with(["sm", P._, P.nullish, P._], () => "px-2 py-1")
    .with(["sm", P._, "dot", "left"], () => "pl-1 pr-2 py-1")
    .with(["sm", P._, "dot", "right"], () => "pl-2 pr-1 py-1")
    .with(["sm", P.nullish, P._, P._], ["sm", "", P._, P._], () => "px-1 py-1")
    .with(["sm", P._, P._, "left"], () => "pl-1 pr-2 py-1")
    .with(["sm", P._, P._, "right"], () => "pl-2 pr-1 py-1")
    .with(["md", P._, P.nullish, P._], () => "px-3.5 py-1.5")
    .with(["md", P._, "dot", "left"], () => "pl-2.5 pr-3.5 py-1.5")
    .with(["md", P._, "dot", "right"], () => "pl-3.5 pr-2.5 py-1.5")
    .with(
      ["md", P.nullish, P._, P._],
      ["md", "", P._, P._],
      () => "px-2.5 py-1.5",
    )
    .with(["md", P._, P._, "left"], () => "pl-2.5 pr-3.5 py-1.5")
    .with(["md", P._, P._, "right"], () => "pl-3.5 pr-2.5 py-1.5")
    .exhaustive();

  const textColor = match(color)
    .with("blue", () => "text-greyscale-900")
    .with("grey", () => "!text-greyscale-900")
    .with("greySubdued", () => "!text-greyscale-900")
    .with("green", () => "!text-greyscale-900")
    .with("red", () => "!text-greyscale-900")
    .with("redSubdued", () => "!text-greyscale-900")
    .with("yellow", () => "!text-greyscale-900")
    .with("yellowSubdued", () => "!text-greyscale-900")
    .with("white", () => "!text-greyscale-900")
    .with("none", () => "!text-greyscale-900")
    .with("darkBlue", () => "!text-greyscale-0")
    .exhaustive();

  const bgColor = match(color)
    .with("green", () => "bg-secondary-200")
    .with("red", () => "bg-critical-300")
    .with("redSubdued", () => "bg-critical-200")
    .with("blue", () => "bg-primary-100")
    .with("grey", () => "bg-greyscale-200")
    .with("greySubdued", () => "bg-greyscale-100")
    .with("yellow", () => "bg-accent-400")
    .with("yellowSubdued", () => "bg-accent-200")
    .with("darkBlue", () => "bg-primary-500")
    .with("white", () => "bg-greyscale-0")
    .with("none", () => "")
    .exhaustive();

  const sizePadding = match(size)
    .with("sm", () => "py-1")
    .with("md", () => "py-[14px]");

  return (
    <span
      className={classNames(
        className,
        "inline-flex flex-shrink-0 items-center rounded-full text-nowrap",
        paddingClass,
        bgColor,
        sizePadding,
      )}
    >
      {iconSide === "left"
        ? renderMaybeIconOrDot(icon, iconSide, color, !text)
        : null}
      <Label size={size === "md" ? "xs" : "xxs"} className={textColor}>
        {text}
      </Label>
      {iconSide === "right"
        ? renderMaybeIconOrDot(icon, iconSide, color, !text)
        : null}
    </span>
  );
};
