import classNames from "classnames";
import React, { useState } from "react";

import { ReactionGroupResponse, ReactionResponse } from "stream-chat";
import {
  DefaultStreamChatGenerics,
  MAX_MESSAGE_REACTIONS_TO_FETCH,
  MessageContextValue,
  ReactionOptions,
  useTranslationContext,
} from "stream-chat-react";
import {
  ReactionDetailsComparator,
  ReactionsComparator,
  ReactionType,
} from "stream-chat-react/dist/components/Reactions/types";
import { useProcessReactions } from "./hooks/useProcessReactions";
import { ReactionsListModal } from "./ReactionsListModal";

export type ReactionsListProps<
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
> = Partial<
  Pick<
    MessageContextValue<StreamChatGenerics>,
    "handleFetchReactions" | "reactionDetailsSort"
  >
> & {
  /** An array of the own reaction objects to distinguish own reactions visually */
  own_reactions?: ReactionResponse<StreamChatGenerics>[];
  /**
   * An object that keeps track of the count of each type of reaction on a message
   * @deprecated This override value is no longer taken into account. Use `reaction_groups` to override reaction counts instead.
   * */
  reaction_counts?: Record<string, number>;
  /** An object containing summary for each reaction type on a message */
  reaction_groups?: Record<string, ReactionGroupResponse>;
  /**
   * @deprecated
   * A list of the currently supported reactions on a message
   * */
  reactionOptions?: ReactionOptions;
  /** An array of the reaction objects to display in the list */
  reactions?: ReactionResponse<StreamChatGenerics>[];
  /** Display the reactions in the list in reverse order, defaults to false */
  reverse?: boolean;
  /** Comparator function to sort the list of reacted users
   * @deprecated use `reactionDetailsSort` instead
   */
  sortReactionDetails?: ReactionDetailsComparator;
  /** Comparator function to sort reactions, defaults to chronological order */
  sortReactions?: ReactionsComparator;

  align?: "left" | "right";
};

const UnMemoizedReactionsList = <
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: ReactionsListProps<StreamChatGenerics>,
) => {
  const {
    handleFetchReactions,
    reactionDetailsSort,
    reverse = false,
    sortReactionDetails,
    align,
    ...rest
  } = props;
  const { existingReactions, hasReactions, totalReactionCount } =
    useProcessReactions(rest);
  const [selectedReactionType, setSelectedReactionType] =
    useState<ReactionType<StreamChatGenerics> | null>(null);
  const { t } = useTranslationContext("ReactionsList");

  const handleReactionButtonClick = (reactionType: string) => {
    if (totalReactionCount > MAX_MESSAGE_REACTIONS_TO_FETCH) {
      return;
    }

    setSelectedReactionType(reactionType as ReactionType<StreamChatGenerics>);
  };

  if (!hasReactions) return null;

  return (
    <>
      <div
        aria-label={t("aria/Reaction list")}
        className={classNames(
          "str-chat__reaction-list str-chat__message-reactions-container absolute -bottom-5",
          {
            // we are stuck with both classes as both are used in CSS
            "str-chat__reaction-list--reverse": reverse,
            "-right-5": align === "right",
            "-left-5": align === "left",
          },
        )}
        data-testid="reaction-list"
        role="figure"
      >
        <div
          className={classNames(
            "str-chat__message-reaction bg-primary-200 dark:bg-greyscale-800 flex px-2 rounded-2xl items-center align-middle h-7 gap-0.5",
          )}
        >
          {existingReactions.map(
            ({
              EmojiComponent,
              isOwnReaction,
              reactionCount,
              reactionType,
            }) => (
              <div
                className={classNames(
                  "str-chat__message-reaction bg-primary-200 dark:bg-greyscale-800 items-center align-middle pt-1",
                  {
                    "str-chat__message-reaction-own": isOwnReaction,
                  },
                )}
                key={reactionType}
              >
                {EmojiComponent && (
                  <button
                    aria-label={`Reactions: ${reactionType}`}
                    data-testid={`reactions-list-button-${reactionType}`}
                    onClick={() => handleReactionButtonClick(reactionType)}
                    type="button"
                  >
                    <EmojiComponent />
                  </button>
                )}
              </div>
            ),
          )}
          <span className="str-chat__reaction-list--counter">
            {totalReactionCount}
          </span>
        </div>
      </div>
      {selectedReactionType !== null && (
        <ReactionsListModal
          handleFetchReactions={handleFetchReactions}
          onClose={() => setSelectedReactionType(null)}
          onSelectedReactionTypeChange={setSelectedReactionType}
          open={selectedReactionType !== null}
          reactions={existingReactions}
          selectedReactionType={selectedReactionType}
          sortReactionDetails={sortReactionDetails}
        />
      )}
    </>
  );
};

/**
 * Component that displays a list of reactions on a message.
 */
export const ReactionsList = React.memo(
  UnMemoizedReactionsList,
) as typeof UnMemoizedReactionsList;
