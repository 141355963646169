import { NameOrder, Viewer } from "../api/generated/graphql";
import { Chat as ChatProvider } from "stream-chat-react";
import { StreamVideo, StreamVideoClient } from "@stream-io/video-react-sdk";
import { EventHandler, StreamChat } from "stream-chat";
import { useEffect, useRef, useState } from "react";
import { useSortingOptions } from "../utils/hooks/useSortingOptions";
import { useCommsContext } from "./CommsProvider";

// for dev
const apiKey = "vfmvsd635hgf";

type ChatWrapperProps = {
  children: React.ReactNode;
  loadingView: JSX.Element;
  nameOrder: NameOrder;
  country: string;
  darkMode: boolean;
  viewer: Pick<Viewer, "id" | "chatToken" | "firstName" | "lastName">;
};

export const ChatWrapper = (props: ChatWrapperProps) => {
  const { children, viewer, darkMode, nameOrder, country } = props;
  const { updateUnreadCount, connectedToClient } = useCommsContext();
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);
  const [videoClient, setVideoClient] = useState<StreamVideoClient | null>(
    null,
  );

  const { nameOrderFn } = useSortingOptions({
    nameOrder,
    country,
  });

  const isInitialized = useRef(false);

  // Initialize clients only once when the component mounts or when viewer changes
  useEffect(() => {
    if (!viewer || isInitialized.current) return;

    isInitialized.current = true;

    const client = new StreamChat(apiKey);
    const videoClient = new StreamVideoClient(apiKey);

    const connectUsers = async () => {
      try {
        await client.connectUser(
          {
            id: viewer.id,
            name: nameOrderFn(viewer),
            image: "https://i.imgur.com/fR9Jz14.png",
          },
          viewer.chatToken,
        );

        await videoClient.connectUser(
          {
            id: viewer.id,
            name: nameOrderFn(viewer),
            image: "https://i.imgur.com/fR9Jz14.png",
          },
          viewer.chatToken,
        );

        setChatClient(client);
        setVideoClient(videoClient);
        updateUnreadCount((client.user?.total_unread_count as number) ?? 0);
      } catch (error) {
        console.error("Error connecting clients:", error);
        isInitialized.current = false; // Reset initialization flag on error
      }
    };

    connectUsers();

    return () => {
      if (client) {
        client.disconnectUser().catch((e) => {
          console.error("Error disconnecting chat client:", e);
        });
      }
      if (videoClient) {
        videoClient.disconnectUser().catch((e) => {
          console.error("Error disconnecting video client:", e);
        });
      }
      setChatClient(null);
      setVideoClient(null);
      // updateConnectedToClient(false);
      isInitialized.current = false; // Reset initialization flag on cleanup
    };
  }, [nameOrderFn, updateUnreadCount, viewer]);

  // Setup chat client event listeners
  useEffect(() => {
    if (!chatClient) return;

    const handleChatEvent: EventHandler = (event) => {
      if (event.total_unread_count !== undefined) {
        updateUnreadCount(event.total_unread_count);
      }
    };

    chatClient.on(handleChatEvent);

    return () => {
      chatClient.off(handleChatEvent);
    };
  }, [chatClient, updateUnreadCount]);

  if (!chatClient || !videoClient) {
    return children;
  }

  return (
    <StreamVideo client={videoClient}>
      <ChatProvider
        client={chatClient}
        theme={darkMode ? "str-chat__theme-dark" : ""}
      >
        {children}
      </ChatProvider>
    </StreamVideo>
  );
};
