import { DateTime } from "luxon";
import Chevron from "../../../assets/chevron-right.svg?react";
import { formatTime } from "../../../utils/dateUtils";
import { useGetVisitDetails, VisitStatus } from "../../../hooks/visitStatus";
import classNames from "classnames";
import { VisitPill } from "./VisitPill";
import { useCareContext } from "../../../providers";
import { TimeFormat } from "../../../api/generated/graphql";
import { Avatar } from "@frontend/lyng/avatar";

type Props = {
  from: DateTime;
  to: DateTime;
  name: string;
  caregiver?: {
    id: string;
    firstName: string | null;
    lastName: string | null;
  };
  clockIn: DateTime | null;
  clockOut: DateTime | null;
  readOnly?: boolean;
  cancelledAt?: string | null;
  importantInformation?: string | null;
};

type Gradient = {
  [key in VisitStatus]?: string | undefined;
};

const getBackground = (status: VisitStatus, readOnly?: boolean) => {
  if (readOnly) return "bg-gray-80";
  if (status === VisitStatus.cancelled)
    return "bg-visit-card-gradient-cancelled";
  if (status === VisitStatus.pending) return "bg-primary-10";
  if (status === VisitStatus.done) return "bg-secondary-900";
  return "bg-visit-card-gradient-base";
};

const getGradient = (status: VisitStatus, readOnly?: boolean) => {
  if (readOnly) return null;

  const gradients: Gradient = {
    [VisitStatus.soon]: "bg-visit-card-gradient-bright",
    [VisitStatus.now]: "bg-visit-card-gradient-bright",
    [VisitStatus.late]: "bg-visit-card-gradient-bright",
    [VisitStatus.away]: "bg-visit-card-gradient-reverse",
    [VisitStatus.over]: "bg-visit-card-gradient-reverse",
  };

  if (!gradients[status]) return null;

  return (
    <div
      className={`absolute inset-0 h-full w-full animate-opacity bg-primary-10 ${gradients[status]}`}
    />
  );
};

const getFontColor = (status: VisitStatus, readOnly?: boolean) => {
  if (readOnly) return "text-gray-20";
  if (status === VisitStatus.away) return "text-secondary-90";
  return "text-gray-20";
};

const getImportantInfoColor = (status: VisitStatus) => {
  if (status === VisitStatus.done) return "bg-secondary-800";
  return "bg-primary-80";
};

const getHelpers = (status: VisitStatus, readOnly?: boolean) => ({
  background: getBackground(status, readOnly),
  importantInfocolor: getImportantInfoColor(status),
  gradient: getGradient(status, readOnly),
  color: getFontColor(status, readOnly),
});

export const VisitCard = ({
  from,
  to,
  name,
  caregiver,
  clockIn,
  clockOut,
  readOnly,
  cancelledAt,
  importantInformation,
}: Props) => {
  const { status, detailsText, pillText } = useGetVisitDetails({
    from,
    to,
    clockIn,
    clockOut,
    readOnly,
    cancelledAt,
  });
  const {
    state: { viewer },
  } = useCareContext();
  const isMeridian = viewer?.tenantSettings.timeFormat === TimeFormat.AmPm;

  const time = `${formatTime(from, isMeridian)} - ${formatTime(to, isMeridian)}`;
  const helpers = getHelpers(status, readOnly);

  return (
    <div>
      <div
        className={`relative overflow-hidden rounded-lg ${helpers.background}`}
      >
        {helpers.gradient}
        <div
          className={classNames(
            { "py-3": !importantInformation && !cancelledAt },
            "relative z-10 flex flex-col pt-3 gap-2",
          )}
        >
          <div className="flex flex-col px-4 gap-1">
            <p className="text-gray-10">{time}</p>
            <p className="text-lg font-medium text-gray-10">{name}</p>
          </div>

          <div className="flex w-full flex-row items-center px-4 gap-2">
            {readOnly && caregiver && (
              <Avatar
                firstName={caregiver?.firstName ?? ""}
                lastName={caregiver?.firstName ?? ""}
              />
            )}
            <div className="flex flex-col">
              {readOnly && caregiver && (
                <p className="text-xs font-medium text-gray-20">
                  {`${caregiver?.firstName ?? ""} ${caregiver?.lastName ?? ""}`}
                </p>
              )}
              <p className={`text-xs font-medium ${helpers.color}`}>
                {detailsText}
              </p>
            </div>
            {pillText && (
              <div className="ml-auto">
                <VisitPill text={pillText} status={status} />
              </div>
            )}
            <div className="absolute right-3 top-14 z-10 -translate-y-1/2">
              <Chevron className="text-gray-10" />
            </div>
          </div>
          {importantInformation && !cancelledAt && (
            <div
              className={`flex mt-2 ${helpers.importantInfocolor} w-full px-4 py-3`}
            >
              <p className="text-small text-gray-10">{importantInformation}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
