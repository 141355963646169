import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
};

export type Absence = {
  __typename?: 'Absence';
  allDay: Scalars['Boolean']['output'];
  description: Maybe<Scalars['String']['output']>;
  end: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reason: AbsenceReason;
  reportedAt: Scalars['String']['output'];
  start: Scalars['String']['output'];
  user: Maybe<User>;
  userId: Scalars['ID']['output'];
};

export enum AbsenceReason {
  CancelledByCareProvider = 'CANCELLED_BY_CARE_PROVIDER',
  CancelledByCareRecipient = 'CANCELLED_BY_CARE_RECIPIENT',
  Holiday = 'HOLIDAY',
  HospitalCareFacility = 'HOSPITAL_CARE_FACILITY',
  Other = 'OTHER',
  PaidLeave = 'PAID_LEAVE',
  PaidSickLeave = 'PAID_SICK_LEAVE',
  SickChild = 'SICK_CHILD',
  Training = 'TRAINING',
  UnpaidLeave = 'UNPAID_LEAVE',
  UnpaidSickChild = 'UNPAID_SICK_CHILD',
  UnpaidSickLeave = 'UNPAID_SICK_LEAVE'
}

export type AccessLog = {
  __typename?: 'AccessLog';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  query: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  user: User;
};

export type AccessLogResponse = {
  __typename?: 'AccessLogResponse';
  cursor: Maybe<Scalars['String']['output']>;
  logs: Array<AccessLog>;
};

export type AccessSettings = {
  __typename?: 'AccessSettings';
  caregiversCanCreateAndUpdateVisits: Scalars['Boolean']['output'];
};

export type Activity = {
  __typename?: 'Activity';
  activityType: Maybe<ActivityType>;
  careRecipient: CareRecipient;
  careRecipientId: Scalars['String']['output'];
  comment: Maybe<Scalars['String']['output']>;
  createdAt: Maybe<Scalars['String']['output']>;
  createdBy: Maybe<User>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDue: Scalars['Boolean']['output'];
  lastCompletedAt: Maybe<Scalars['String']['output']>;
  priority: Maybe<Priority>;
  schedule: Maybe<ActivitySchedule>;
  status: Maybe<ActivityStatus>;
  template: Maybe<ActivityTemplate>;
  timeOfDayStart: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Maybe<Scalars['String']['output']>;
  updatedBy: Maybe<User>;
  /** @deprecated usused */
  weekdays: Maybe<Array<Weekday>>;
};

export type ActivityCreateInput = {
  activityTypeId?: InputMaybe<Scalars['String']['input']>;
  careRecipientId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  priority?: InputMaybe<Priority>;
  schedule?: InputMaybe<ActivityScheduleRuleInput>;
  timeOfDayStart?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  /** @deprecated unused */
  weekdays?: InputMaybe<Array<Weekday>>;
};

export type ActivityGroup = {
  __typename?: 'ActivityGroup';
  activityTemplates: Array<ActivityTemplate>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ActivityGroupInput = {
  activityTemplateIds: Array<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  caregiver: Maybe<CareGiver>;
  comment: Maybe<Scalars['String']['output']>;
  completionTime: Scalars['String']['output'];
  status: Maybe<ActivityStatus>;
  visit: Maybe<Visit>;
};

export type ActivityLogResponse = {
  __typename?: 'ActivityLogResponse';
  activityLogs: Array<ActivityLog>;
  cursor: Maybe<Scalars['String']['output']>;
};

export type ActivitySchedule = ActivityScheduleEveryNDays | ActivityScheduleEveryVisit | ActivityScheduleOneOff;

export type ActivityScheduleEveryNDays = {
  __typename?: 'ActivityScheduleEveryNDays';
  days: Scalars['Int']['output'];
};

export type ActivityScheduleEveryVisit = {
  __typename?: 'ActivityScheduleEveryVisit';
  everyVisit: Scalars['String']['output'];
};

export type ActivityScheduleInput = {
  activityId: Scalars['String']['input'];
  visitDefinitionId: Scalars['String']['input'];
};

export type ActivityScheduleOneOff = {
  __typename?: 'ActivityScheduleOneOff';
  date: Scalars['String']['output'];
};

export type ActivityScheduleRuleEveryNDays = {
  days: Scalars['Int']['input'];
};

export type ActivityScheduleRuleEveryVisit = {
  everyDay?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ActivityScheduleRuleInput = {
  everyNDays?: InputMaybe<ActivityScheduleRuleEveryNDays>;
  everyVisit?: InputMaybe<ActivityScheduleRuleEveryVisit>;
  oneOff?: InputMaybe<ActivityScheduleRuleOneOff>;
};

export type ActivityScheduleRuleOneOff = {
  date: Scalars['String']['input'];
};

export enum ActivityStatus {
  Completed = 'COMPLETED',
  CouldNotComplete = 'COULD_NOT_COMPLETE',
  NotStarted = 'NOT_STARTED'
}

export type ActivityStatusUpdateInput = {
  activityId: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  status: ActivityStatus;
};

export type ActivityTemplate = {
  __typename?: 'ActivityTemplate';
  activityType: Maybe<ActivityType>;
  description: Scalars['String']['output'];
  groups: Array<ActivityGroup>;
  id: Scalars['ID']['output'];
  organizationUnits: Array<OrganizationUnit>;
  priority: Maybe<Priority>;
  schedule: Maybe<ActivitySchedule>;
  timeOfDayStart: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type ActivityTemplateInput = {
  activityGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  activityTypeId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  priority?: InputMaybe<Priority>;
  resourceIds: Array<Scalars['String']['input']>;
  schedule?: InputMaybe<ActivityScheduleRuleInput>;
  timeOfDayStart?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type ActivityTemplateUpdate = {
  activityGroupIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  activityTypeId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  priority?: InputMaybe<Priority>;
  resourceIds: Array<Scalars['String']['input']>;
  schedule?: InputMaybe<ActivityScheduleRuleInput>;
  timeOfDayStart?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type ActivityType = {
  __typename?: 'ActivityType';
  custom: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type ActivityUpdateInput = {
  activityTypeId?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  priority?: InputMaybe<Priority>;
  schedule?: InputMaybe<ActivityScheduleRuleInput>;
  timeOfDayStart?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  /** @deprecated unused */
  weekdays?: InputMaybe<Array<Weekday>>;
};

export type Address = {
  __typename?: 'Address';
  addressLine1: Maybe<Scalars['String']['output']>;
  addressLine2: Maybe<Scalars['String']['output']>;
  city: Maybe<Scalars['String']['output']>;
  homeInformation: Maybe<Scalars['String']['output']>;
  state: Maybe<Scalars['String']['output']>;
  zipCode: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  homeInformation?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type AnnouncementChannelCreateInput = {
  name: Scalars['String']['input'];
  officeId: Scalars['String']['input'];
};

export enum AppType {
  Mobile = 'MOBILE',
  Team = 'TEAM'
}

export enum AvailabilityForLabel {
  Available = 'AVAILABLE',
  Conflict = 'CONFLICT',
  NotAvailable = 'NOT_AVAILABLE'
}

export enum BillingType {
  Fixed = 'FIXED',
  Hourly = 'HOURLY'
}

export type BootstrapTenantInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  tenantName: Scalars['String']['input'];
};

export enum CancelledReason {
  CancelledByCareProvider = 'CANCELLED_BY_CARE_PROVIDER',
  CancelledByCareRecipient = 'CANCELLED_BY_CARE_RECIPIENT',
  Holiday = 'HOLIDAY',
  HospitalCareFacility = 'HOSPITAL_CARE_FACILITY',
  Other = 'OTHER'
}

export type CareGiver = {
  __typename?: 'CareGiver';
  birthDate: Maybe<Scalars['String']['output']>;
  caregiverRoles: Array<CaregiverRole>;
  /** @deprecated Use caregiverRoles instead */
  deactivatedAt: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  events: Array<Event>;
  firstName: Maybe<Scalars['String']['output']>;
  /** @deprecated Use caregiverRoles instead */
  hireDate: Scalars['String']['output'];
  homePhone: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  /** @deprecated Use caregiverRoles instead */
  office: Maybe<Office>;
  phone: Maybe<Scalars['String']['output']>;
  pid: Maybe<Scalars['String']['output']>;
  visits: Array<Visit>;
};


export type CareGiverEventsArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type CareGiverVisitsArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type CareGiverFilter = {
  officeIds: Array<Scalars['String']['input']>;
};

export type CarePlan = {
  __typename?: 'CarePlan';
  fields: Array<Field>;
  id: Scalars['String']['output'];
};

export type CarePlanInput = {
  fields: Array<FieldInput>;
};

export type CareRecipient = {
  __typename?: 'CareRecipient';
  activities: Array<Activity>;
  address: Maybe<Address>;
  birthDate: Maybe<Scalars['String']['output']>;
  carePlan: CarePlan;
  careRecipientRoles: Array<CareRecipientRole>;
  carelogs: Array<Carelog>;
  contacts: Array<ContactForCareRecipient>;
  /** @deprecated Use care recipient roles instead */
  deactivatedAt: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  events: Array<Event>;
  files: Array<File>;
  firstName: Maybe<Scalars['String']['output']>;
  gender: Maybe<Gender>;
  homePhone: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  /** @deprecated Use careRecipientRoles instead */
  office: Maybe<Office>;
  phone: Maybe<Scalars['String']['output']>;
  pid: Maybe<Scalars['String']['output']>;
  primaryContact: Maybe<ContactForCareRecipient>;
  visits: Array<Visit>;
};


export type CareRecipientCarelogsArgs = {
  from?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};


export type CareRecipientEventsArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type CareRecipientVisitsArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type CareRecipientAbsenceCreateInput = {
  allDay: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  reason: CareRecipientAbsenceReason;
  reportedAt: Scalars['String']['input'];
  start: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export enum CareRecipientAbsenceReason {
  CancelledByCareProvider = 'CANCELLED_BY_CARE_PROVIDER',
  CancelledByCareRecipient = 'CANCELLED_BY_CARE_RECIPIENT',
  Holiday = 'HOLIDAY',
  HospitalCareFacility = 'HOSPITAL_CARE_FACILITY',
  Other = 'OTHER'
}

export type CareRecipientAbsenceUpdateInput = {
  allDay: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  reason: CareRecipientAbsenceReason;
  reportedAt: Scalars['String']['input'];
  start: Scalars['String']['input'];
};

export type CareRecipientCreateInput = {
  address?: InputMaybe<AddressInput>;
  /** Date format YYYY-MM-DD */
  birthDate?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emergencyContact?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  hasReadAccess?: InputMaybe<Scalars['Boolean']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  officeId: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type CareRecipientFilter = {
  officeIds: Array<Scalars['String']['input']>;
};

export type CareRecipientRole = {
  __typename?: 'CareRecipientRole';
  /** @deprecated Use birth date on care recipient */
  birthDate: Maybe<Scalars['String']['output']>;
  deactivatedAt: Maybe<Scalars['String']['output']>;
  hasReadAccess: Maybe<Scalars['Boolean']['output']>;
  office: Office;
  /** @deprecated Use pid on care recipient */
  ssn: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type CareRecipientRoleInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  hasReadAccess?: InputMaybe<Scalars['Boolean']['input']>;
  officeId: Scalars['String']['input'];
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type CareRecipientTotal = {
  __typename?: 'CareRecipientTotal';
  careRecipient: CareRecipient;
  sections: Array<CareRecipientTotalsSection>;
};

export type CareRecipientTotalsSection = {
  __typename?: 'CareRecipientTotalsSection';
  billingKey: Scalars['String']['output'];
  totalHours: Scalars['Float']['output'];
};

export type CareRecipientUpdateInput = {
  address?: InputMaybe<AddressInput>;
  /** Date format YYYY-MM-DD */
  birthDate?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  hasReadAccess?: InputMaybe<Scalars['Boolean']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  officeId: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  ssn?: InputMaybe<Scalars['String']['input']>;
};

export type CareRecipientsTotalsReport = {
  __typename?: 'CareRecipientsTotalsReport';
  totals: Array<CareRecipientTotal>;
};

export type CareTeamMember = {
  __typename?: 'CareTeamMember';
  birthDate: Maybe<Scalars['String']['output']>;
  careTeamMemberRoles: Array<CareTeamMemberRole>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  homePhone: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  pid: Maybe<Scalars['String']['output']>;
};

export type CareTeamMemberCreateInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pid?: InputMaybe<Scalars['String']['input']>;
  resourceId: Scalars['ID']['input'];
  resourceType: ResourceType;
  roleType: CareTeamMemberRoleType;
};

export type CareTeamMemberDeactivateInput = {
  resourceId: Scalars['ID']['input'];
  roleType: CareTeamMemberRoleType;
};

export type CareTeamMemberDeleteInput = {
  resourceId: Scalars['ID']['input'];
  roleType: CareTeamMemberRoleType;
};

export type CareTeamMemberReactivateInput = {
  resourceId: Scalars['ID']['input'];
  roleType: CareTeamMemberRoleType;
};

export type CareTeamMemberRole = {
  __typename?: 'CareTeamMemberRole';
  deactivatedAt: Maybe<Scalars['String']['output']>;
  organizationUnit: OrganizationUnit;
  resourceId: Scalars['ID']['output'];
  resourceType: ResourceType;
  roleType: CareTeamMemberRoleType;
  userId: Scalars['ID']['output'];
};

export type CareTeamMemberRoleInput = {
  resourceId: Scalars['ID']['input'];
  resourceType: ResourceType;
  roleType: CareTeamMemberRoleType;
};

export enum CareTeamMemberRoleType {
  Admin = 'ADMIN',
  SchedulingManager = 'SCHEDULING_MANAGER',
  SuccessManager = 'SUCCESS_MANAGER'
}

export type CareTeamMemberUpdateInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pid?: InputMaybe<Scalars['String']['input']>;
};

export type CaregiverAbsenceCreateInput = {
  allDay: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  reason: CaregiverAbsenceReason;
  reportedAt: Scalars['String']['input'];
  start: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export enum CaregiverAbsenceReason {
  Other = 'OTHER',
  PaidLeave = 'PAID_LEAVE',
  PaidSickLeave = 'PAID_SICK_LEAVE',
  SickChild = 'SICK_CHILD',
  Training = 'TRAINING',
  UnpaidLeave = 'UNPAID_LEAVE',
  UnpaidSickChild = 'UNPAID_SICK_CHILD',
  UnpaidSickLeave = 'UNPAID_SICK_LEAVE'
}

export type CaregiverAbsenceUpdateInput = {
  allDay: Scalars['Boolean']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['String']['input']>;
  reason: CaregiverAbsenceReason;
  reportedAt: Scalars['String']['input'];
  start: Scalars['String']['input'];
};

export type CaregiverCreateInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emergencyContact?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  /** Date format YYYY-MM-DD */
  hireDate: Scalars['String']['input'];
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  officeId: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pid?: InputMaybe<Scalars['String']['input']>;
};

export type CaregiverRole = {
  __typename?: 'CaregiverRole';
  deactivatedAt: Maybe<Scalars['String']['output']>;
  emergencyContact: Maybe<Scalars['String']['output']>;
  hireDate: Maybe<Scalars['String']['output']>;
  office: Office;
  userId: Scalars['ID']['output'];
};

export type CaregiverRoleInput = {
  emergencyContact?: InputMaybe<Scalars['String']['input']>;
  hireDate?: InputMaybe<Scalars['String']['input']>;
  officeId: Scalars['String']['input'];
};

export type CaregiverUpdateInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  emergencyContact?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  /** Date format YYYY-MM-DD */
  hireDate: Scalars['String']['input'];
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  officeId: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pid?: InputMaybe<Scalars['String']['input']>;
};

export type Carelog = {
  __typename?: 'Carelog';
  author: User;
  careRecipient: CareRecipient;
  careRecipientId: Scalars['ID']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  log: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
};

export type CarelogInput = {
  log: Scalars['String']['input'];
};

export type CarelogUpdate = {
  log: Scalars['String']['input'];
};

export type Channel = {
  __typename?: 'Channel';
  channelType: ChannelType;
  id: Scalars['String']['output'];
  name: Maybe<Scalars['String']['output']>;
};

export enum ChannelType {
  Annoucement = 'ANNOUCEMENT',
  Group = 'GROUP',
  Private = 'PRIVATE'
}

export type CheckboxField = {
  __typename?: 'CheckboxField';
  availableValues: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  values: Array<Scalars['String']['output']>;
};

export type ClockInInput = {
  caregiverId?: InputMaybe<Scalars['String']['input']>;
  clockInLocation?: InputMaybe<GpsPointInput>;
  visitInstanceId: Scalars['String']['input'];
};

export type ClockOutInput = {
  caregiverId?: InputMaybe<Scalars['String']['input']>;
  clockOutLocation?: InputMaybe<GpsPointInput>;
  clockOutTime?: InputMaybe<Scalars['String']['input']>;
  expenses?: InputMaybe<Array<ExpenseInput>>;
  mileage?: InputMaybe<MileageInput>;
  visitInstanceId: Scalars['String']['input'];
  visitNote: Scalars['String']['input'];
};

export type Contact = {
  __typename?: 'Contact';
  birthDate: Maybe<Scalars['String']['output']>;
  contactRoles: Array<ContactRole>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  homePhone: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  pid: Maybe<Scalars['String']['output']>;
};

export type ContactCreateInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  careRecipientId: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  hasPowerOfAttorney: Scalars['Boolean']['input'];
  hasReadAccess?: InputMaybe<Scalars['Boolean']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  isPayer: Scalars['Boolean']['input'];
  isPrimary: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pid?: InputMaybe<Scalars['String']['input']>;
  relationshipType: RelationshipType;
};

export type ContactForCareRecipient = {
  __typename?: 'ContactForCareRecipient';
  birthDate: Maybe<Scalars['String']['output']>;
  deactivatedAt: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  hasPowerOfAttorney: Scalars['Boolean']['output'];
  hasReadAccess: Scalars['Boolean']['output'];
  homePhone: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPayer: Scalars['Boolean']['output'];
  isPrimary: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  pid: Maybe<Scalars['String']['output']>;
  relationshipType: RelationshipType;
};

export type ContactRole = {
  __typename?: 'ContactRole';
  careRecipient: CareRecipient;
  careRecipientId: Scalars['ID']['output'];
  deactivateAt: Maybe<Scalars['String']['output']>;
  hasPowerOfAttorney: Scalars['Boolean']['output'];
  hasReadAccess: Scalars['Boolean']['output'];
  isPayer: Scalars['Boolean']['output'];
  isPrimary: Scalars['Boolean']['output'];
  relationshipType: RelationshipType;
  userId: Scalars['ID']['output'];
};

export type ContactUpdateInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  hasPowerOfAttorney: Scalars['Boolean']['input'];
  hasReadAccess?: InputMaybe<Scalars['Boolean']['input']>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  isPayer: Scalars['Boolean']['input'];
  isPrimary: Scalars['Boolean']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pid?: InputMaybe<Scalars['String']['input']>;
  relationshipType: RelationshipType;
};

export type DateField = {
  __typename?: 'DateField';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export enum DateFormat {
  DdMmYyyy = 'DD_MM_YYYY',
  MmDdYyyy = 'MM_DD_YYYY'
}

export enum DecimalSeparator {
  Comma = 'COMMA',
  Dot = 'DOT'
}

export enum DistanceUnit {
  Kilometers = 'KILOMETERS',
  Miles = 'MILES'
}

export type Division = {
  __typename?: 'Division';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  regions: Array<Region>;
  settings: Maybe<DivisionSettings>;
};

export type DivisionAccess = {
  __typename?: 'DivisionAccess';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type DivisionCreateInput = {
  name: Scalars['String']['input'];
};

export type DivisionSettings = {
  __typename?: 'DivisionSettings';
  caregiversCanCreateAndUpdateVisits: Scalars['Boolean']['output'];
  divisionId: Scalars['ID']['output'];
};

export type DivisionSettingsInput = {
  caregiversCanCreateAndUpdateVisits: Scalars['Boolean']['input'];
  divisionId: Scalars['ID']['input'];
};

export type DivisionUpdateInput = {
  name: Scalars['String']['input'];
};

export type Event = Absence | Visit;

export type Expense = {
  __typename?: 'Expense';
  billedAmount: Maybe<Scalars['Decimal']['output']>;
  description: Scalars['String']['output'];
  reimbursedAmount: Maybe<Scalars['Decimal']['output']>;
  reportedAmount: Maybe<Scalars['Decimal']['output']>;
};

export type ExpenseAdminInput = {
  billedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  description: Scalars['String']['input'];
  reimbursedAmount?: InputMaybe<Scalars['Decimal']['input']>;
  reportedAmount?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ExpenseInput = {
  description: Scalars['String']['input'];
  reportedAmount?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ExpenseTotals = {
  __typename?: 'ExpenseTotals';
  billedAmount: Maybe<Scalars['Decimal']['output']>;
  reimbursedAmount: Maybe<Scalars['Decimal']['output']>;
  reportedAmount: Maybe<Scalars['Decimal']['output']>;
};

export type FeedItem = Carelog | Visit;

export type Field = CheckboxField | DateField | RadioField | TextArea | TextField;

export type FieldInput = {
  id: Scalars['String']['input'];
  type: InputFieldType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type File = {
  __typename?: 'File';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum Frequency {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type GpsPoint = {
  __typename?: 'GpsPoint';
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type GpsPointInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type GroupChannelCreateInput = {
  name: Scalars['String']['input'];
  officeId: Scalars['String']['input'];
};

export type HashedString = {
  __typename?: 'HashedString';
  hashed: Scalars['String']['output'];
  unhashed: Scalars['String']['output'];
};

export type Holiday = {
  __typename?: 'Holiday';
  /** Date format YYYY-MM-DD */
  date: Scalars['String']['output'];
  id: Scalars['String']['output'];
  localName: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Info = {
  __typename?: 'Info';
  version: Scalars['String']['output'];
};

export enum InputFieldType {
  CheckboxField = 'CHECKBOX_FIELD',
  DateField = 'DATE_FIELD',
  RadioField = 'RADIO_FIELD',
  TextArea = 'TEXT_AREA',
  TextField = 'TEXT_FIELD'
}

export type Integration = {
  __typename?: 'Integration';
  settings: IntegrationSettings;
  type: IntegrationType;
};

export enum IntegrationFieldType {
  ApiKey = 'API_KEY',
  Password = 'PASSWORD',
  Site = 'SITE',
  Username = 'USERNAME'
}

export type IntegrationInput = {
  fieldType: IntegrationFieldType;
  value: Scalars['String']['input'];
};

export type IntegrationRun = {
  __typename?: 'IntegrationRun';
  from: Scalars['String']['output'];
  id: Scalars['String']['output'];
  integrationStatus: IntegrationStatus;
  integrationType: IntegrationType;
  to: Scalars['String']['output'];
};

export type IntegrationSettings = IntegrationSettingsApiKey | IntegrationSettingsUserPass;

export type IntegrationSettingsApiKey = {
  __typename?: 'IntegrationSettingsApiKey';
  hasValue: Scalars['Boolean']['output'];
};

export type IntegrationSettingsUserPass = {
  __typename?: 'IntegrationSettingsUserPass';
  passwordHasValue: Scalars['Boolean']['output'];
  site: Maybe<Scalars['String']['output']>;
  userName: Maybe<Scalars['String']['output']>;
};

export enum IntegrationStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS'
}

export enum IntegrationType {
  Wise = 'WISE'
}

export type Label = {
  __typename?: 'Label';
  id: Scalars['String']['output'];
  inUse: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  officeId: Scalars['String']['output'];
};

export type LabelCreateInput = {
  name: Scalars['String']['input'];
  officeId: Scalars['String']['input'];
};

export type LabelUpdateInput = {
  labelId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export enum Language {
  En = 'EN',
  Es = 'ES',
  Is = 'IS'
}

export type Mileage = {
  __typename?: 'Mileage';
  drivenForCareRecipientBilled: Maybe<Scalars['Decimal']['output']>;
  drivenForCareRecipientReimbursed: Maybe<Scalars['Decimal']['output']>;
  drivenForCareRecipientReported: Maybe<Scalars['Decimal']['output']>;
  drivenToLocationBilled: Maybe<Scalars['Decimal']['output']>;
  drivenToLocationReimbursed: Maybe<Scalars['Decimal']['output']>;
  drivenToLocationReported: Maybe<Scalars['Decimal']['output']>;
};

export type MileageAdminInput = {
  drivenForCareRecipientBilled?: InputMaybe<Scalars['Decimal']['input']>;
  drivenForCareRecipientReimbursed?: InputMaybe<Scalars['Decimal']['input']>;
  drivenForCareRecipientReported?: InputMaybe<Scalars['Decimal']['input']>;
  drivenToLocationBilled?: InputMaybe<Scalars['Decimal']['input']>;
  drivenToLocationReimbursed?: InputMaybe<Scalars['Decimal']['input']>;
  drivenToLocationReported?: InputMaybe<Scalars['Decimal']['input']>;
};

export type MileageInput = {
  drivenForCareRecipientReported?: InputMaybe<Scalars['Decimal']['input']>;
  drivenToLocationReported?: InputMaybe<Scalars['Decimal']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  absenceCreateForCareRecipient: Absence;
  absenceCreateForCaregiver: Absence;
  absenceDelete: Maybe<Scalars['Boolean']['output']>;
  absenceUpdateForCareRecipient: Absence;
  absenceUpdateForCaregiver: Absence;
  activityCreate: Activity;
  activityCreateAndSchedule: Activity;
  activityCreateFromTemplates: Array<Activity>;
  activityDelete: Maybe<Scalars['Boolean']['output']>;
  activityGroupCreate: ActivityGroup;
  activityGroupDelete: Scalars['Boolean']['output'];
  activityGroupUpdate: ActivityGroup;
  activitySchedule: Maybe<Scalars['Boolean']['output']>;
  activityStatusesUpdate: Maybe<Scalars['Boolean']['output']>;
  activityTemplateCreate: ActivityTemplate;
  activityTemplateDelete: Scalars['Boolean']['output'];
  activityTemplateUpdate: ActivityTemplate;
  activityUnschedule: Maybe<Scalars['Boolean']['output']>;
  activityUpdate: Activity;
  bootstrapTenant: Scalars['Boolean']['output'];
  carePlanForCareRecipientUpsert: CarePlan;
  carePlanSetForDivision: Scalars['Boolean']['output'];
  careRecipientCreate: CareRecipient;
  careRecipientDeactivate: Maybe<CareRecipient>;
  careRecipientFileDelete: Maybe<Scalars['Boolean']['output']>;
  careRecipientFileUploadUrl: Scalars['String']['output'];
  careRecipientReactivate: Maybe<CareRecipient>;
  careRecipientUpdate: Maybe<CareRecipient>;
  careTeamMemberCreate: CareTeamMember;
  careTeamMemberDeactivate: CareTeamMember;
  /** @deprecated Use UserDelete */
  careTeamMemberDelete: Maybe<Scalars['Boolean']['output']>;
  careTeamMemberReactivate: CareTeamMember;
  careTeamMemberUpdate: CareTeamMember;
  caregiverCreate: CareGiver;
  caregiverDeactivate: CareGiver;
  /** @deprecated Use UserDelete */
  caregiverDelete: Maybe<Scalars['Boolean']['output']>;
  caregiverReactivate: CareGiver;
  caregiverUpdate: CareGiver;
  carelogForCareRecipientInsert: Carelog;
  carelogForCareRecipientUpdate: Carelog;
  careplansMigrateForDivision: Scalars['Boolean']['output'];
  clockIn: Maybe<Scalars['Boolean']['output']>;
  clockOut: Maybe<Scalars['Boolean']['output']>;
  contactCreate: Contact;
  contactDeactivate: ContactForCareRecipient;
  contactReactivate: ContactForCareRecipient;
  contactUpdate: Contact;
  divisionCreate: Division;
  divisionSettingsUpdate: DivisionSettings;
  divisionUpdate: Division;
  integrationSettingsUpsert: Scalars['Boolean']['output'];
  labelCreate: Label;
  labelDelete: Maybe<Scalars['Boolean']['output']>;
  labelUpdate: Label;
  officeCreate: Office;
  officeUpdate: Office;
  privateChannelCreate: Channel;
  regionCreate: Region;
  regionUpdate: Region;
  searchIndexReindex: Scalars['Boolean']['output'];
  tenantSettingsSetEnableLabels: TenantSettings;
  tenantSettingsSetEnableMileageExpense: TenantSettings;
  tenantSettingsSetEnableVisitBilling: TenantSettings;
  tenantSettingsUpdate: TenantSettings;
  tenantUpdate: Tenant;
  userCareRecipientRoleAdd: User;
  userCareTeamMemberRoleAdd: User;
  userCaregiverRoleAdd: User;
  userCreate: User;
  userDelete: Scalars['Boolean']['output'];
  userInvitationResend: Scalars['Boolean']['output'];
  userRoleDeactivate: User;
  userRoleReactivate: User;
  userUpdate: User;
  visitCancel: Visit;
  visitCreate: VisitDefinition;
  visitDelete: Maybe<Scalars['Boolean']['output']>;
  visitLogCreate: Visit;
  visitLogUpdate: Visit;
  visitReactivate: Visit;
  visitTypeCreate: VisitType;
  visitTypeUpdate: VisitType;
  visitUpdate: Visit;
  visitUpdateFollowing: VisitDefinition;
  visitlogReportIntegrate: Scalars['String']['output'];
  visitsAssignVisitorsByLabelId: Array<Visit>;
  webPushSubscriptionRegister: Scalars['Boolean']['output'];
  webPushTest: Scalars['Boolean']['output'];
};


export type MutationAbsenceCreateForCareRecipientArgs = {
  input: CareRecipientAbsenceCreateInput;
};


export type MutationAbsenceCreateForCaregiverArgs = {
  input: CaregiverAbsenceCreateInput;
};


export type MutationAbsenceDeleteArgs = {
  absenceId: Scalars['ID']['input'];
};


export type MutationAbsenceUpdateForCareRecipientArgs = {
  id: Scalars['ID']['input'];
  input: CareRecipientAbsenceUpdateInput;
};


export type MutationAbsenceUpdateForCaregiverArgs = {
  id: Scalars['ID']['input'];
  input: CaregiverAbsenceUpdateInput;
};


export type MutationActivityCreateArgs = {
  input: ActivityCreateInput;
};


export type MutationActivityCreateAndScheduleArgs = {
  input: ActivityCreateInput;
  visitDefinitionId: Scalars['String']['input'];
};


export type MutationActivityCreateFromTemplatesArgs = {
  careRecipientId: Scalars['String']['input'];
  templateIds: Array<Scalars['String']['input']>;
};


export type MutationActivityDeleteArgs = {
  activityId: Scalars['ID']['input'];
};


export type MutationActivityGroupCreateArgs = {
  input: ActivityGroupInput;
};


export type MutationActivityGroupDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActivityGroupUpdateArgs = {
  id: Scalars['ID']['input'];
  input: ActivityGroupInput;
};


export type MutationActivityScheduleArgs = {
  input: ActivityScheduleInput;
};


export type MutationActivityStatusesUpdateArgs = {
  input: Array<ActivityStatusUpdateInput>;
  visitInstanceId: Scalars['ID']['input'];
};


export type MutationActivityTemplateCreateArgs = {
  input: ActivityTemplateInput;
};


export type MutationActivityTemplateDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActivityTemplateUpdateArgs = {
  id: Scalars['ID']['input'];
  input: ActivityTemplateUpdate;
};


export type MutationActivityUnscheduleArgs = {
  activityId: Scalars['ID']['input'];
  visitDefinitionId: Scalars['ID']['input'];
};


export type MutationActivityUpdateArgs = {
  activityId: Scalars['ID']['input'];
  input: ActivityUpdateInput;
};


export type MutationBootstrapTenantArgs = {
  input: BootstrapTenantInput;
};


export type MutationCarePlanForCareRecipientUpsertArgs = {
  careRecipientId: Scalars['String']['input'];
  input: CarePlanInput;
};


export type MutationCarePlanSetForDivisionArgs = {
  careplanId: Scalars['String']['input'];
  divisionId: Scalars['ID']['input'];
};


export type MutationCareRecipientCreateArgs = {
  input: CareRecipientCreateInput;
};


export type MutationCareRecipientDeactivateArgs = {
  id: Scalars['ID']['input'];
  officeId: Scalars['ID']['input'];
};


export type MutationCareRecipientFileDeleteArgs = {
  fileName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationCareRecipientFileUploadUrlArgs = {
  fileName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationCareRecipientReactivateArgs = {
  id: Scalars['ID']['input'];
  officeId: Scalars['ID']['input'];
};


export type MutationCareRecipientUpdateArgs = {
  id: Scalars['ID']['input'];
  input: CareRecipientUpdateInput;
};


export type MutationCareTeamMemberCreateArgs = {
  input: CareTeamMemberCreateInput;
};


export type MutationCareTeamMemberDeactivateArgs = {
  id: Scalars['ID']['input'];
  input: CareTeamMemberDeactivateInput;
};


export type MutationCareTeamMemberDeleteArgs = {
  id: Scalars['ID']['input'];
  input: CareTeamMemberDeleteInput;
};


export type MutationCareTeamMemberReactivateArgs = {
  id: Scalars['ID']['input'];
  input: CareTeamMemberReactivateInput;
};


export type MutationCareTeamMemberUpdateArgs = {
  id: Scalars['ID']['input'];
  input: CareTeamMemberUpdateInput;
};


export type MutationCaregiverCreateArgs = {
  input: CaregiverCreateInput;
};


export type MutationCaregiverDeactivateArgs = {
  id: Scalars['ID']['input'];
  officeId: Scalars['ID']['input'];
};


export type MutationCaregiverDeleteArgs = {
  id: Scalars['ID']['input'];
  officeId: Scalars['ID']['input'];
};


export type MutationCaregiverReactivateArgs = {
  id: Scalars['ID']['input'];
  officeId: Scalars['ID']['input'];
};


export type MutationCaregiverUpdateArgs = {
  id: Scalars['ID']['input'];
  input: CaregiverUpdateInput;
};


export type MutationCarelogForCareRecipientInsertArgs = {
  careRecipientId: Scalars['String']['input'];
  input: CarelogInput;
  officeId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCarelogForCareRecipientUpdateArgs = {
  carelogId: Scalars['String']['input'];
  input: CarelogUpdate;
};


export type MutationCareplansMigrateForDivisionArgs = {
  divisionId: Scalars['ID']['input'];
};


export type MutationClockInArgs = {
  input: ClockInInput;
};


export type MutationClockOutArgs = {
  input: ClockOutInput;
};


export type MutationContactCreateArgs = {
  input: ContactCreateInput;
};


export type MutationContactDeactivateArgs = {
  careRecipientId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
};


export type MutationContactReactivateArgs = {
  careRecipientId: Scalars['ID']['input'];
  contactId: Scalars['ID']['input'];
};


export type MutationContactUpdateArgs = {
  careRecipientId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  input: ContactUpdateInput;
};


export type MutationDivisionCreateArgs = {
  input?: InputMaybe<DivisionCreateInput>;
};


export type MutationDivisionSettingsUpdateArgs = {
  input: DivisionSettingsInput;
};


export type MutationDivisionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: DivisionUpdateInput;
};


export type MutationIntegrationSettingsUpsertArgs = {
  inputs: Array<IntegrationInput>;
  integrationType: IntegrationType;
};


export type MutationLabelCreateArgs = {
  input: LabelCreateInput;
};


export type MutationLabelDeleteArgs = {
  labelId: Scalars['ID']['input'];
};


export type MutationLabelUpdateArgs = {
  input: LabelUpdateInput;
};


export type MutationOfficeCreateArgs = {
  input: OfficeCreateInput;
};


export type MutationOfficeUpdateArgs = {
  id: Scalars['ID']['input'];
  input: OfficeUpdateInput;
};


export type MutationPrivateChannelCreateArgs = {
  input: PrivateChannelCreateInput;
};


export type MutationRegionCreateArgs = {
  input: RegionCreateInput;
};


export type MutationRegionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: RegionUpdateInput;
};


export type MutationSearchIndexReindexArgs = {
  index: SearchIndex;
  key: Scalars['String']['input'];
};


export type MutationTenantSettingsSetEnableLabelsArgs = {
  enabled: Scalars['Boolean']['input'];
};


export type MutationTenantSettingsSetEnableMileageExpenseArgs = {
  enabled: Scalars['Boolean']['input'];
};


export type MutationTenantSettingsSetEnableVisitBillingArgs = {
  enabled: Scalars['Boolean']['input'];
};


export type MutationTenantSettingsUpdateArgs = {
  input: TenantSettingsInput;
};


export type MutationTenantUpdateArgs = {
  input?: InputMaybe<TenantUpdateInput>;
};


export type MutationUserCareRecipientRoleAddArgs = {
  input: CareRecipientRoleInput;
  userId: Scalars['String']['input'];
};


export type MutationUserCareTeamMemberRoleAddArgs = {
  input: CareTeamMemberRoleInput;
  userId: Scalars['String']['input'];
};


export type MutationUserCaregiverRoleAddArgs = {
  input: CaregiverRoleInput;
  userId: Scalars['String']['input'];
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserDeleteArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationUserInvitationResendArgs = {
  userId: Scalars['String']['input'];
};


export type MutationUserRoleDeactivateArgs = {
  resourceId: Scalars['String']['input'];
  resourceType: ResourceType;
  roleType: RoleType;
  userId: Scalars['ID']['input'];
};


export type MutationUserRoleReactivateArgs = {
  resourceId: Scalars['String']['input'];
  resourceType: ResourceType;
  roleType: RoleType;
  userId: Scalars['ID']['input'];
};


export type MutationUserUpdateArgs = {
  id: Scalars['String']['input'];
  input: UserUpdateInput;
};


export type MutationVisitCancelArgs = {
  cancelFollowing?: Scalars['Boolean']['input'];
  cancelledAt: Scalars['String']['input'];
  cancelledReason: CancelledReason;
  visitInstanceId: Scalars['ID']['input'];
};


export type MutationVisitCreateArgs = {
  input: VisitCreateInput;
};


export type MutationVisitDeleteArgs = {
  deleteFollowing: Scalars['Boolean']['input'];
  visitId: Scalars['ID']['input'];
};


export type MutationVisitLogCreateArgs = {
  input: VisitLogCreateInput;
};


export type MutationVisitLogUpdateArgs = {
  input: VisitLogUpdateInput;
  visitInstanceId: Scalars['ID']['input'];
};


export type MutationVisitReactivateArgs = {
  reactivateFollowing?: Scalars['Boolean']['input'];
  visitInstanceId: Scalars['ID']['input'];
};


export type MutationVisitTypeCreateArgs = {
  input: VisitTypeCreateInput;
};


export type MutationVisitTypeUpdateArgs = {
  id: Scalars['ID']['input'];
  input: VisitTypeUpdateInput;
};


export type MutationVisitUpdateArgs = {
  input: VisitUpdateInput;
};


export type MutationVisitUpdateFollowingArgs = {
  input: VisitUpdateFollowingInput;
};


export type MutationVisitlogReportIntegrateArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type MutationVisitsAssignVisitorsByLabelIdArgs = {
  from: Scalars['String']['input'];
  labelId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
  visitorIds: Array<Scalars['ID']['input']>;
};


export type MutationWebPushSubscriptionRegisterArgs = {
  app: AppType;
  subscription: WebPushSubscriptionRegistrationInput;
};


export type MutationWebPushTestArgs = {
  notificationContent: Scalars['String']['input'];
  subscriberId: Scalars['String']['input'];
};

export enum NameOrder {
  FirstLast = 'FIRST_LAST',
  LastFirst = 'LAST_FIRST'
}

export type NovuSubscriberIds = {
  __typename?: 'NovuSubscriberIds';
  mobile: HashedString;
  office: HashedString;
};

export type Office = {
  __typename?: 'Office';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  region: Maybe<Region>;
};

export type OfficeAccess = {
  __typename?: 'OfficeAccess';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
  settings: AccessSettings;
};

export type OfficeCreateInput = {
  name: Scalars['String']['input'];
  regionId: Scalars['String']['input'];
};

export type OfficeUpdateInput = {
  name: Scalars['String']['input'];
};

export type OnVisitUpdatedFilter = {
  careRecipientIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  officeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  visitInstanceIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  visitorIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Organization = {
  __typename?: 'Organization';
  divisions: Array<Division>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type OrganizationUnit = Division | Office | Region | Tenant;

export type PingPayload = {
  __typename?: 'PingPayload';
  ping: Scalars['String']['output'];
  tenant: Tenant;
  user: User;
};

export enum Priority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Optional = 'OPTIONAL'
}

export type PrivateChannelCreateInput = {
  member: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  absenceById: Absence;
  absencesByOfficeId: Array<Absence>;
  accessLogs: AccessLogResponse;
  activitiesByCareRecipientId: Array<Activity>;
  activityById: Activity;
  activityGroupById: ActivityGroup;
  activityGroups: Array<ActivityGroup>;
  activityGroupsForOffice: Array<ActivityGroup>;
  activityLogsByActivityId: ActivityLogResponse;
  activityTemplateById: ActivityTemplate;
  activityTemplates: Array<ActivityTemplate>;
  activityTemplatesForOffice: Array<ActivityTemplate>;
  activityTypes: Array<ActivityType>;
  availableUsersForChat: Array<User>;
  careGiverById: CareGiver;
  careGivers: Array<CareGiver>;
  careGiversCount: Scalars['Int']['output'];
  careRecipientById: Maybe<CareRecipient>;
  careRecipients: Array<CareRecipient>;
  careRecipientsCount: Scalars['Int']['output'];
  careTeamMemberById: CareTeamMember;
  careTeamMembers: Array<CareTeamMember>;
  caregiverAvailabilitiesByLabelId: Array<VisitorAvailabilityForLabel>;
  caregiverAvailabilitiesByOfficeIds: Array<VisitorAvailability>;
  caregiverAvailability: VisitorAvailability;
  contactById: Contact;
  contactsByCareRecipientId: Array<ContactForCareRecipient>;
  eventsByCareRecipientId: Array<Event>;
  eventsByCaregiverId: Array<Event>;
  eventsByOfficeIds: Array<Event>;
  feedItems: Array<FeedItem>;
  feedItemsByCareRecipientId: Array<FeedItem>;
  holidaysForYear: Array<Holiday>;
  info: Info;
  integrationRunById: Maybe<IntegrationRun>;
  integrationRuns: Array<IntegrationRun>;
  integrations: Array<Integration>;
  labelById: Label;
  labelsByOfficeIds: Array<Label>;
  loginUsers: Array<User>;
  offices: Array<Office>;
  organization: Organization;
  teamMemberSearchKeys: Maybe<TeamMemberSearchKeys>;
  userById: User;
  users: Array<User>;
  usersByPid: Array<User>;
  viewer: Viewer;
  visitById: Maybe<Visit>;
  visitDefinitionById: VisitDefinition;
  /** @deprecated Use visitorReports */
  visitLogsByOfficeId: Array<Visit>;
  visitTypeById: VisitType;
  visitTypeHistoryById: Array<VisitType>;
  visitTypes: Array<VisitType>;
  visitTypesByDate: Array<VisitType>;
  visitlogsTotalsReport: CareRecipientsTotalsReport;
  visitorReportByOfficeId: Array<VisitorReport>;
  visitorReports: Array<VisitorReport>;
  visitsByCareRecipientId: Array<Visit>;
  visitsByCaregiverId: Array<Visit>;
  visitsByContactId: Array<Visit>;
};


export type QueryAbsenceByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAbsencesByOfficeIdArgs = {
  from: Scalars['String']['input'];
  officeId: Scalars['ID']['input'];
  roleType: RoleType;
  to: Scalars['String']['input'];
};


export type QueryAccessLogsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryActivitiesByCareRecipientIdArgs = {
  careRecipientId: Scalars['ID']['input'];
};


export type QueryActivityByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryActivityGroupByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryActivityGroupsForOfficeArgs = {
  officeId: Scalars['ID']['input'];
};


export type QueryActivityLogsByActivityIdArgs = {
  activityId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};


export type QueryActivityTemplateByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryActivityTemplatesForOfficeArgs = {
  officeId: Scalars['ID']['input'];
};


export type QueryCareGiverByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCareGiversArgs = {
  filter: CareGiverFilter;
};


export type QueryCareGiversCountArgs = {
  officeIds: Array<Scalars['ID']['input']>;
};


export type QueryCareRecipientByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCareRecipientsArgs = {
  filter: CareRecipientFilter;
};


export type QueryCareRecipientsCountArgs = {
  officeIds: Array<Scalars['ID']['input']>;
};


export type QueryCareTeamMemberByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCaregiverAvailabilitiesByLabelIdArgs = {
  from: Scalars['String']['input'];
  labelId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
};


export type QueryCaregiverAvailabilitiesByOfficeIdsArgs = {
  input: VisitorAvailabilityInput;
  officeIds: Array<Scalars['ID']['input']>;
};


export type QueryCaregiverAvailabilityArgs = {
  caregiverId: Scalars['ID']['input'];
  input: VisitorAvailabilityInput;
};


export type QueryContactByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactsByCareRecipientIdArgs = {
  careRecipientId: Scalars['ID']['input'];
};


export type QueryEventsByCareRecipientIdArgs = {
  careRecipientId: Scalars['ID']['input'];
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type QueryEventsByCaregiverIdArgs = {
  caregiverId: Scalars['ID']['input'];
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type QueryEventsByOfficeIdsArgs = {
  from: Scalars['String']['input'];
  officeIds: Array<Scalars['ID']['input']>;
  to: Scalars['String']['input'];
};


export type QueryFeedItemsArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type QueryFeedItemsByCareRecipientIdArgs = {
  careRecipientId: Scalars['ID']['input'];
  from?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};


export type QueryHolidaysForYearArgs = {
  countryCode: Scalars['String']['input'];
  year: Scalars['Int']['input'];
};


export type QueryIntegrationRunByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryLabelByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryLabelsByOfficeIdsArgs = {
  officeIds: Array<Scalars['String']['input']>;
};


export type QueryUserByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersByPidArgs = {
  pid: Scalars['String']['input'];
};


export type QueryVisitByIdArgs = {
  visitInstanceId: Scalars['ID']['input'];
};


export type QueryVisitDefinitionByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitLogsByOfficeIdArgs = {
  from: Scalars['String']['input'];
  officeId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
};


export type QueryVisitTypeByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitTypeHistoryByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitTypesByDateArgs = {
  date: Scalars['String']['input'];
};


export type QueryVisitlogsTotalsReportArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type QueryVisitorReportByOfficeIdArgs = {
  from: Scalars['String']['input'];
  officeId: Scalars['ID']['input'];
  to: Scalars['String']['input'];
};


export type QueryVisitorReportsArgs = {
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
  visitorReportsFilter?: InputMaybe<VisitorReportsFilter>;
};


export type QueryVisitsByCareRecipientIdArgs = {
  careRecipientId: Scalars['ID']['input'];
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type QueryVisitsByCaregiverIdArgs = {
  caregiverId: Scalars['ID']['input'];
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};


export type QueryVisitsByContactIdArgs = {
  contactId: Scalars['ID']['input'];
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type RRule = {
  __typename?: 'RRule';
  frequency: Frequency;
  interval: Scalars['Int']['output'];
  weekStart: Weekday;
  weekdays: Maybe<Array<Weekday>>;
};

export type RRuleInput = {
  frequency: Frequency;
  interval?: InputMaybe<Scalars['Int']['input']>;
  weekStart: Weekday;
  weekdays?: InputMaybe<Array<Weekday>>;
};

export type RadioField = {
  __typename?: 'RadioField';
  availableValues: Array<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type Recurrence = {
  __typename?: 'Recurrence';
  durationMinutes: Scalars['Int']['output'];
  end: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rRule: Maybe<RRule>;
  start: Scalars['String']['output'];
  visitTypeId: Maybe<Scalars['String']['output']>;
  visitorIds: Array<Scalars['String']['output']>;
};

export type Region = {
  __typename?: 'Region';
  division: Division;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offices: Array<Office>;
};

export type RegionAccess = {
  __typename?: 'RegionAccess';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roles: Array<Role>;
};

export type RegionCreateInput = {
  divisionId: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type RegionUpdateInput = {
  name: Scalars['String']['input'];
};

export enum RelationshipType {
  Family = 'FAMILY',
  Friend = 'FRIEND',
  Medical = 'MEDICAL',
  Other = 'OTHER',
  Spouse = 'SPOUSE',
  Therapy = 'THERAPY'
}

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: ResourceType;
};

export enum ResourceType {
  CareRecipient = 'CARE_RECIPIENT',
  Division = 'DIVISION',
  Office = 'OFFICE',
  Region = 'REGION',
  Tenant = 'TENANT'
}

export type Role = {
  __typename?: 'Role';
  deactivateAt: Maybe<Scalars['String']['output']>;
  resource: Resource;
  /** @deprecated Use resource.Id */
  resourceId: Scalars['String']['output'];
  /** @deprecated Use resource.Type */
  resourceType: ResourceType;
  roleType: RoleType;
  userId: Scalars['ID']['output'];
};

export enum RoleType {
  Admin = 'ADMIN',
  Caregiver = 'CAREGIVER',
  CareRecipient = 'CARE_RECIPIENT',
  Contact = 'CONTACT',
  SchedulingManager = 'SCHEDULING_MANAGER',
  SuccessManager = 'SUCCESS_MANAGER'
}

export enum SearchIndex {
  Users = 'USERS'
}

export type SearchKey = {
  __typename?: 'SearchKey';
  expiresAt: Scalars['Int']['output'];
  key: Scalars['String']['output'];
};

export type Sentiment = {
  __typename?: 'Sentiment';
  mixed: Scalars['Float']['output'];
  negative: Scalars['Float']['output'];
  neutral: Scalars['Float']['output'];
  positive: Scalars['Float']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onVisitUpdated: VisitUpdated;
  ping: PingPayload;
};


export type SubscriptionOnVisitUpdatedArgs = {
  filter?: InputMaybe<OnVisitUpdatedFilter>;
};

export type TeamMemberSearchKeys = {
  __typename?: 'TeamMemberSearchKeys';
  usersKey: SearchKey;
};

export type Tenant = {
  __typename?: 'Tenant';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSingleSignOn: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type TenantAccess = {
  __typename?: 'TenantAccess';
  divisions: Array<DivisionAccess>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offices: Array<OfficeAccess>;
  regions: Array<RegionAccess>;
  roles: Array<Role>;
};

export type TenantSettings = {
  __typename?: 'TenantSettings';
  country: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  dateFormat: DateFormat;
  decimalSeparator: DecimalSeparator;
  distanceUnit: DistanceUnit;
  divisionSettings: Array<DivisionSettings>;
  eSignatureRequired: Scalars['Boolean']['output'];
  enableLabels: Scalars['Boolean']['output'];
  enableMileageExpense: Scalars['Boolean']['output'];
  enableVisitBilling: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  language: Language;
  locationRequired: Scalars['Boolean']['output'];
  nameOrder: NameOrder;
  showSsn: Scalars['Boolean']['output'];
  timeFormat: TimeFormat;
  timezone: Scalars['String']['output'];
};

export type TenantSettingsInput = {
  country: Scalars['String']['input'];
  currency: Scalars['String']['input'];
  dateFormat: DateFormat;
  decimalSeparator: DecimalSeparator;
  distanceUnit: DistanceUnit;
  eSignatureRequired?: InputMaybe<Scalars['Boolean']['input']>;
  language: Language;
  locationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  nameOrder: NameOrder;
  showSsn: Scalars['Boolean']['input'];
  timeFormat: TimeFormat;
  timezone: Scalars['String']['input'];
};

export type TenantUpdateInput = {
  name: Scalars['String']['input'];
};

export type TextArea = {
  __typename?: 'TextArea';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export type TextField = {
  __typename?: 'TextField';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
  value: Maybe<Scalars['String']['output']>;
};

export enum TimeFormat {
  AmPm = 'AM_PM',
  TwentyFourHour = 'TWENTY_FOUR_HOUR'
}

export type User = {
  __typename?: 'User';
  birthDate: Maybe<Scalars['String']['output']>;
  careRecipientRoles: Array<CareRecipientRole>;
  careTeamMemberRoles: Array<CareTeamMemberRole>;
  caregiverRoles: Array<CaregiverRole>;
  contactRoles: Array<ContactRole>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  homePhone: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isTenantOwner: Scalars['Boolean']['output'];
  lastLogin: Maybe<Scalars['String']['output']>;
  lastName: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  pid: Maybe<Scalars['String']['output']>;
  /** @deprecated each role type will have specific array */
  roles: Array<Role>;
};

export type UserCreateInput = {
  address?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  careRecipientRoles?: InputMaybe<Array<CareRecipientRoleInput>>;
  careTeamMemberRoles?: InputMaybe<Array<CareTeamMemberRoleInput>>;
  caregiverRoles?: InputMaybe<Array<CaregiverRoleInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  pid?: InputMaybe<Scalars['String']['input']>;
};

export type UserUpdateInput = {
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  gender?: InputMaybe<Gender>;
  homePhone?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Viewer = {
  __typename?: 'Viewer';
  address: Maybe<Address>;
  chatToken: Maybe<Scalars['String']['output']>;
  email: Maybe<Scalars['String']['output']>;
  firstName: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intercomId: Scalars['String']['output'];
  isTenantOwner: Scalars['Boolean']['output'];
  lastName: Maybe<Scalars['String']['output']>;
  /** @deprecated Use novuSubscriberIds and the appropriate id per app */
  novuSubscriberId: Scalars['String']['output'];
  novuSubscriberIds: NovuSubscriberIds;
  /** @deprecated Use offices */
  office: Maybe<Office>;
  offices: Array<Office>;
  roles: Array<Role>;
  teamMemberSearchKeys: Maybe<TeamMemberSearchKeys>;
  tenantAccess: TenantAccess;
  tenantSettings: TenantSettings;
  tenants: Array<Tenant>;
  vapidKey: Scalars['String']['output'];
};

export type Visit = {
  __typename?: 'Visit';
  activities: Array<Activity>;
  billingDurations: Maybe<VisitBillingDurations>;
  /** @deprecated Use cancelledAt */
  cancelled: Scalars['Boolean']['output'];
  cancelledAt: Maybe<Scalars['String']['output']>;
  cancelledReason: Maybe<CancelledReason>;
  careRecipient: CareRecipient;
  careRecipientId: Scalars['ID']['output'];
  clockInLocation: Maybe<GpsPoint>;
  clockInTime: Maybe<Scalars['String']['output']>;
  clockOutLocation: Maybe<GpsPoint>;
  clockOutTime: Maybe<Scalars['String']['output']>;
  durationMinutes: Scalars['Int']['output'];
  exceptionId: Maybe<Scalars['ID']['output']>;
  expenseTotals: Maybe<ExpenseTotals>;
  expenses: Array<Expense>;
  history: Array<VisitHistoryItem>;
  id: Scalars['ID']['output'];
  labelIds: Array<Scalars['ID']['output']>;
  labels: Array<Label>;
  mileage: Maybe<Mileage>;
  officeId: Scalars['ID']['output'];
  recurrence: Maybe<Recurrence>;
  start: Scalars['String']['output'];
  visitDefinition: VisitDefinition;
  visitDefinitionId: Maybe<Scalars['ID']['output']>;
  /** @deprecated Use activities */
  visitLogActivities: Array<VisitLogActivity>;
  visitNote: Maybe<Scalars['String']['output']>;
  visitNoteSentiment: Maybe<Sentiment>;
  visitType: Maybe<VisitType>;
  visitTypeId: Maybe<Scalars['String']['output']>;
  visitorIds: Array<Scalars['String']['output']>;
  visitors: Array<CareGiver>;
};

export type VisitBillingDurations = {
  __typename?: 'VisitBillingDurations';
  scheduledBaseDurationMinutes: Scalars['Int']['output'];
  scheduledEveningDurationMinutes: Scalars['Int']['output'];
  scheduledNightDurationMinutes: Scalars['Int']['output'];
  workedBaseDurationMinutes: Maybe<Scalars['Int']['output']>;
  workedEveningDurationMinutes: Maybe<Scalars['Int']['output']>;
  workedNightDurationMinutes: Maybe<Scalars['Int']['output']>;
};

export type VisitCreateInput = {
  activityIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  careRecipientId: Scalars['String']['input'];
  durationMinutes: Scalars['Int']['input'];
  /** ISO formatted date string with timezone */
  end?: InputMaybe<Scalars['String']['input']>;
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  officeId?: InputMaybe<Scalars['String']['input']>;
  /** If recurrence is not provided this will treated as a one-off visit */
  rRule?: InputMaybe<RRuleInput>;
  residentialCare?: InputMaybe<Scalars['Boolean']['input']>;
  /** ISO formatted date string with timezone */
  start: Scalars['String']['input'];
  visitTypeId?: InputMaybe<Scalars['String']['input']>;
  visitorIds: Array<Scalars['String']['input']>;
};

export type VisitDefinition = {
  __typename?: 'VisitDefinition';
  careRecipientId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  recurrences: Array<Recurrence>;
  residentialCare: Maybe<Scalars['Boolean']['output']>;
};

export enum VisitHistoryEvent {
  ActivityStatusUpdated = 'ACTIVITY_STATUS_UPDATED',
  Cancelled = 'CANCELLED',
  ClockIn = 'CLOCK_IN',
  ClockOut = 'CLOCK_OUT',
  ManualCreate = 'MANUAL_CREATE',
  Reactivated = 'REACTIVATED',
  Unknown = 'UNKNOWN',
  Update = 'UPDATE',
  VisitStarting = 'VISIT_STARTING'
}

export type VisitHistoryItem = {
  __typename?: 'VisitHistoryItem';
  event: Maybe<VisitHistoryEvent>;
  updatedAt: Maybe<Scalars['String']['output']>;
  updatedBy: Maybe<User>;
  version: Scalars['Int']['output'];
  visitInstanceId: Scalars['ID']['output'];
};

export type VisitLogActivity = {
  __typename?: 'VisitLogActivity';
  activityId: Scalars['ID']['output'];
  activityType: Maybe<ActivityType>;
  comment: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  priority: Maybe<Priority>;
  status: ActivityStatus;
  timeOfDayStart: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  weekDays: Maybe<Array<Weekday>>;
};

export type VisitLogCreateInput = {
  activityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  careRecipientId: Scalars['String']['input'];
  clockInTime?: InputMaybe<Scalars['String']['input']>;
  clockOutTime?: InputMaybe<Scalars['String']['input']>;
  durationMinutes: Scalars['Int']['input'];
  expenses?: InputMaybe<Array<ExpenseAdminInput>>;
  labelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mileage?: InputMaybe<MileageAdminInput>;
  officeId: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
  visitNote?: InputMaybe<Scalars['String']['input']>;
  visitTypeId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Not used */
  visitTypeVersion?: InputMaybe<Scalars['Int']['input']>;
  visitorIds: Array<Scalars['String']['input']>;
};

export type VisitLogUpdateInput = {
  cancelledAt?: InputMaybe<Scalars['String']['input']>;
  cancelledReason?: InputMaybe<CancelledReason>;
  clockIn?: InputMaybe<Scalars['String']['input']>;
  clockOut?: InputMaybe<Scalars['String']['input']>;
  durationMinutes: Scalars['Int']['input'];
  expenses?: InputMaybe<Array<ExpenseAdminInput>>;
  labelIds?: InputMaybe<Array<Scalars['String']['input']>>;
  mileage?: InputMaybe<MileageAdminInput>;
  startDate: Scalars['String']['input'];
  visitNote?: InputMaybe<Scalars['String']['input']>;
  visitTypeId?: InputMaybe<Scalars['String']['input']>;
  /** @deprecated Not used */
  visitTypeVersion?: InputMaybe<Scalars['Int']['input']>;
  visitorIds: Array<Scalars['String']['input']>;
};

export type VisitType = {
  __typename?: 'VisitType';
  activeFrom: Scalars['String']['output'];
  activeTo: Maybe<Scalars['String']['output']>;
  basePeriodStart: Scalars['String']['output'];
  baseRate: Scalars['Decimal']['output'];
  billingType: BillingType;
  code: Scalars['String']['output'];
  /** @deprecated Use nightPeriodStart or basePeriodStart */
  eveningPeriodEnd: Maybe<Scalars['String']['output']>;
  eveningPeriodStart: Maybe<Scalars['String']['output']>;
  eveningRate: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['ID']['output'];
  /** @deprecated Use basePeriodStart or eveningPeriodStart */
  nightPeriodEnd: Maybe<Scalars['String']['output']>;
  nightPeriodStart: Maybe<Scalars['String']['output']>;
  nightRate: Maybe<Scalars['Decimal']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  updatedBy: Maybe<User>;
  /** @deprecated Not used */
  version: Scalars['Int']['output'];
};

export type VisitTypeCreateInput = {
  basePeriodStart?: InputMaybe<Scalars['String']['input']>;
  baseRate: Scalars['Decimal']['input'];
  billingType: BillingType;
  code: Scalars['String']['input'];
  /** @deprecated  */
  eveningPeriodEnd?: InputMaybe<Scalars['String']['input']>;
  eveningPeriodStart?: InputMaybe<Scalars['String']['input']>;
  eveningRate?: InputMaybe<Scalars['Decimal']['input']>;
  /** @deprecated  */
  nightPeriodEnd?: InputMaybe<Scalars['String']['input']>;
  nightPeriodStart?: InputMaybe<Scalars['String']['input']>;
  nightRate?: InputMaybe<Scalars['Decimal']['input']>;
  title: Scalars['String']['input'];
};

export type VisitTypeUpdateInput = {
  basePeriodStart?: InputMaybe<Scalars['String']['input']>;
  baseRate: Scalars['Decimal']['input'];
  billingType: BillingType;
  code: Scalars['String']['input'];
  /** @deprecated  */
  eveningPeriodEnd?: InputMaybe<Scalars['String']['input']>;
  eveningPeriodStart?: InputMaybe<Scalars['String']['input']>;
  eveningRate?: InputMaybe<Scalars['Decimal']['input']>;
  /** @deprecated  */
  nightPeriodEnd?: InputMaybe<Scalars['String']['input']>;
  nightPeriodStart?: InputMaybe<Scalars['String']['input']>;
  nightRate?: InputMaybe<Scalars['Decimal']['input']>;
  title: Scalars['String']['input'];
};

export type VisitUpdateFollowingInput = {
  durationMinutes: Scalars['Int']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  rRule?: InputMaybe<RRuleInput>;
  start: Scalars['String']['input'];
  visitInstanceId: Scalars['ID']['input'];
  visitTypeId?: InputMaybe<Scalars['String']['input']>;
  visitorIds: Array<Scalars['String']['input']>;
};

export type VisitUpdateInput = {
  /** @deprecated  */
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  cancelledAt?: InputMaybe<Scalars['String']['input']>;
  cancelledReason?: InputMaybe<CancelledReason>;
  durationMinutes?: InputMaybe<Scalars['Int']['input']>;
  labelIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  start?: InputMaybe<Scalars['String']['input']>;
  visitInstanceId: Scalars['ID']['input'];
  visitTypeId?: InputMaybe<Scalars['String']['input']>;
  visitorIds: Array<Scalars['String']['input']>;
};

export type VisitUpdated = {
  __typename?: 'VisitUpdated';
  event: VisitHistoryEvent;
  visit: Visit;
};

export type VisitorAvailability = {
  __typename?: 'VisitorAvailability';
  allVisits: Scalars['Boolean']['output'];
  conflictFirstVisit: Scalars['Boolean']['output'];
  conflictInSeries: Scalars['Boolean']['output'];
  firstVisit: Scalars['Boolean']['output'];
  visitor: CareGiver;
  visitorId: Scalars['ID']['output'];
};

export type VisitorAvailabilityForLabel = {
  __typename?: 'VisitorAvailabilityForLabel';
  availability: AvailabilityForLabel;
  visitor: CareGiver;
  visitorId: Scalars['ID']['output'];
};

export type VisitorAvailabilityInput = {
  durationMinutes: Scalars['Int']['input'];
  end?: InputMaybe<Scalars['String']['input']>;
  rRule?: InputMaybe<RRuleInput>;
  start: Scalars['String']['input'];
  /** @deprecated Use visitDefinitionIds */
  visitDefinitionId?: InputMaybe<Scalars['ID']['input']>;
  visitDefinitionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type VisitorReport = {
  __typename?: 'VisitorReport';
  numberOfVisits: Scalars['Int']['output'];
  timeScheduledMinutes: Scalars['Int']['output'];
  timeWorkedMinutes: Scalars['Int']['output'];
  visitor: CareGiver;
  visitorId: Scalars['ID']['output'];
};

export type VisitorReportsFilter = {
  OfficeIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type WebPushSubscriptionRegistrationInput = {
  endpoint: Scalars['String']['input'];
  subscriptionKey: WebPushSubscriptionRegistrationKeyInput;
};

export type WebPushSubscriptionRegistrationKeyInput = {
  Auth: Scalars['String']['input'];
  P256dh: Scalars['String']['input'];
};

export enum Weekday {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type AvailableUsersForChatQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableUsersForChatQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, firstName: string | null, lastName: string | null, phone: string | null, email: string | null }> };

export type UserByIdQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type UserByIdQuery = { __typename?: 'Query', userById: { __typename?: 'User', id: string, firstName: string | null, lastName: string | null } };

export type WebPushSubscriptionRegistraterMutationVariables = Exact<{
  app: AppType;
  input: WebPushSubscriptionRegistrationInput;
}>;


export type WebPushSubscriptionRegistraterMutation = { __typename?: 'Mutation', webPushSubscriptionRegister: boolean };


export const AvailableUsersForChatDocument = gql`
    query AvailableUsersForChat {
  users {
    id
    firstName
    lastName
    phone
    email
  }
}
    `;

/**
 * __useAvailableUsersForChatQuery__
 *
 * To run a query within a React component, call `useAvailableUsersForChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableUsersForChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableUsersForChatQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableUsersForChatQuery(baseOptions?: Apollo.QueryHookOptions<AvailableUsersForChatQuery, AvailableUsersForChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableUsersForChatQuery, AvailableUsersForChatQueryVariables>(AvailableUsersForChatDocument, options);
      }
export function useAvailableUsersForChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableUsersForChatQuery, AvailableUsersForChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableUsersForChatQuery, AvailableUsersForChatQueryVariables>(AvailableUsersForChatDocument, options);
        }
export function useAvailableUsersForChatSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AvailableUsersForChatQuery, AvailableUsersForChatQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableUsersForChatQuery, AvailableUsersForChatQueryVariables>(AvailableUsersForChatDocument, options);
        }
export type AvailableUsersForChatQueryHookResult = ReturnType<typeof useAvailableUsersForChatQuery>;
export type AvailableUsersForChatLazyQueryHookResult = ReturnType<typeof useAvailableUsersForChatLazyQuery>;
export type AvailableUsersForChatSuspenseQueryHookResult = ReturnType<typeof useAvailableUsersForChatSuspenseQuery>;
export type AvailableUsersForChatQueryResult = Apollo.QueryResult<AvailableUsersForChatQuery, AvailableUsersForChatQueryVariables>;
export const UserByIdDocument = gql`
    query UserById($id: ID!) {
  userById(id: $id) {
    id
    firstName
    lastName
  }
}
    `;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables> & ({ variables: UserByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export function useUserByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, options);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdSuspenseQueryHookResult = ReturnType<typeof useUserByIdSuspenseQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const WebPushSubscriptionRegistraterDocument = gql`
    mutation WebPushSubscriptionRegistrater($app: AppType!, $input: WebPushSubscriptionRegistrationInput!) {
  webPushSubscriptionRegister(app: $app, subscription: $input)
}
    `;
export type WebPushSubscriptionRegistraterMutationFn = Apollo.MutationFunction<WebPushSubscriptionRegistraterMutation, WebPushSubscriptionRegistraterMutationVariables>;

/**
 * __useWebPushSubscriptionRegistraterMutation__
 *
 * To run a mutation, you first call `useWebPushSubscriptionRegistraterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebPushSubscriptionRegistraterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webPushSubscriptionRegistraterMutation, { data, loading, error }] = useWebPushSubscriptionRegistraterMutation({
 *   variables: {
 *      app: // value for 'app'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWebPushSubscriptionRegistraterMutation(baseOptions?: Apollo.MutationHookOptions<WebPushSubscriptionRegistraterMutation, WebPushSubscriptionRegistraterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WebPushSubscriptionRegistraterMutation, WebPushSubscriptionRegistraterMutationVariables>(WebPushSubscriptionRegistraterDocument, options);
      }
export type WebPushSubscriptionRegistraterMutationHookResult = ReturnType<typeof useWebPushSubscriptionRegistraterMutation>;
export type WebPushSubscriptionRegistraterMutationResult = Apollo.MutationResult<WebPushSubscriptionRegistraterMutation>;
export type WebPushSubscriptionRegistraterMutationOptions = Apollo.BaseMutationOptions<WebPushSubscriptionRegistraterMutation, WebPushSubscriptionRegistraterMutationVariables>;