import classNames from "classnames";
import React from "react";

import {
  MessageErrorIcon,
  MessageDeleted as DefaultMessageDeleted,
  MessageStatus as DefaultMessageStatus,
  MessageText,
  MessageTimestamp as DefaultMessageTimestamp,
  areMessageUIPropsEqual,
  messageHasAttachments,
  messageHasReactions,
  EditMessageForm as DefaultEditMessageForm,
  MessageInput,
  MML,
  Modal,
  useComponentContext,
  MessageContextValue,
  useMessageContext,
  MessageUIComponentProps,
  DefaultStreamChatGenerics,
  MessageOptions,
  Attachment,
} from "stream-chat-react";
import { ReactionsList } from "./ReactionsList";
import { Avatar } from "../avatar";
import { useUserByIdQuery } from "../api/generated/graphql";

type MessageSimpleWithContextProps<
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
> = MessageContextValue<StreamChatGenerics>;

const MessageSimpleWithContext = <
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: MessageSimpleWithContextProps<StreamChatGenerics>,
) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleRetry,
    highlighted,
    isMyMessage,
    message,
    renderText,
  } = props;

  const {
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    MessageStatus = DefaultMessageStatus,
    MessageTimestamp = DefaultMessageTimestamp,
  } = useComponentContext<StreamChatGenerics>("MessageSimple");

  const { data } = useUserByIdQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: message.user?.id ?? "" },
  });

  const firstName = data?.userById.firstName;
  const lastName = data?.userById.lastName;

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);

  if (message.deleted_at || message.type === "deleted") {
    return <MessageDeleted message={message} />;
  }

  const canShowReactions = hasReactions;

  const allowRetry =
    message.status === "failed" && message.errorStatusCode !== 403;

  const rootClassName = classNames(
    "str-chat__message str-chat__message-simple",
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage()
      ? "str-chat__message--me str-chat__message-simple--me"
      : "str-chat__message--other",
    message.text ? "str-chat__message--has-text" : "has-no-text",
    {
      "pinned-message": message.pinned,
      "str-chat__message--has-attachment": hasAttachment,
      "str-chat__message--highlighted": highlighted,
      "str-chat__message--with-reactions str-chat__message-with-thread-link":
        canShowReactions,
      "str-chat__message-send-can-be-retried":
        message?.status === "failed" && message?.errorStatusCode !== 403,
      "str-chat__virtual-message__wrapper--end": endOfGroup,
      "str-chat__virtual-message__wrapper--first": firstOfGroup,
      "str-chat__virtual-message__wrapper--group": groupedByUser,
      "h-16": hasReactions,
    },
  );

  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            hideSendButton
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <div className={rootClassName} key={message.id}>
          {message.user && !isMyMessage() && (
            <Avatar
              firstName={firstName ?? ""}
              lastName={lastName ?? ""}
              randomizeColor
            />
          )}
          <div
            className={classNames("str-chat__message-inner", {
              "str-chat__simple-message--error-failed": allowRetry,
            })}
            data-testid="message-inner"
            onClick={allowRetry ? () => handleRetry(message) : undefined}
            onKeyUp={allowRetry ? () => handleRetry(message) : undefined}
          >
            <MessageOptions />
            <div className="str-chat__message-bubble">
              {message.attachments?.length && !message.quoted_message ? (
                <Attachment
                  actionHandler={handleAction}
                  attachments={message.attachments}
                />
              ) : null}
              <MessageText message={message} renderText={renderText} />
              <div className="str-chat__message-reactions-host">
                {canShowReactions && (
                  <ReactionsList
                    reverse
                    align={isMyMessage() ? "left" : "right"}
                  />
                )}
              </div>
              {message.mml && (
                <MML
                  actionHandler={handleAction}
                  align={isMyMessage() ? "right" : "left"}
                  source={message.mml}
                />
              )}
              <MessageErrorIcon />
            </div>
          </div>
          <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
            <MessageStatus />
            <MessageTimestamp
              calendar={false}
              format={"hh:mm:ss A"}
              customClass={classNames("str-chat__message-simple-timestamp", {
                "pt-4": hasReactions,
              })}
            />
          </div>
        </div>
      }
    </>
  );
};

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual,
) as typeof MessageSimpleWithContext;

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const MessageSimple = <
  StreamChatGenerics extends
    DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: MessageUIComponentProps<StreamChatGenerics>,
) => {
  const messageContext = useMessageContext<StreamChatGenerics>("MessageSimple");

  return <MemoizedMessageSimple {...messageContext} {...props} />;
};
