import { Label, Paragraph } from "../typography";
import React, { forwardRef, useState } from "react";
import { Schedule } from "../assets/icons/16/outline";
import { Edit } from "../assets/icons/16/outline";
import { FeedLog } from "./types";
import { useTranslate } from "@tolgee/react";
import { DateTime } from "luxon";
import { DateSettings, useDateFormatter } from "../utils/dateUtils";
import { Button } from "../button/Button";
import { SentimentPill } from "../pill/sentimentPill";

type Props = {
  log: FeedLog;
  onClick: (id: string, type: string) => void;
  dateSettings: DateSettings;
  className?: string;
};

const ActivitiesStatusSentence = ({ logItem }: { logItem: FeedLog }) => {
  const { t } = useTranslate();
  if (logItem.completedActivites === null || logItem.numberOfActivites === 0) {
    return;
  }

  let sentence = "";
  if (logItem.completedActivites === 0) {
    sentence = `${t("activityStatus.noActivitiesCompleted") ?? ""}`;
  } else if (logItem.numberOfActivites === logItem.completedActivites) {
    sentence = `${t("activityStatus.allActivitiesCompleted") ?? ""}`;
  } else {
    sentence = `${logItem.completedActivites}/${logItem.numberOfActivites} ${t("activityStatus.activitiesCompleted") ?? ""}`;
  }
  return (
    <Label size="xxs" className="pt-4">
      {sentence}
    </Label>
  );
};

// Truncate the log if it is too long
// We want to include all words up to 120 letters including the last word
// If the last word is longer than 120 letters we need to truncate it
const truncateLog = (log: string) => {
  const words = log.split(" ");
  let truncatedLog = "";
  let currentLength = 0;
  for (const word of words) {
    if (currentLength + word.length > 120) {
      break;
    }
    truncatedLog += word + " ";
    currentLength += word.length;
  }
  return truncatedLog.trim();
};

const shouldTruncate = (log: string) => {
  return log.length > 120;
};

export const ActivitiesFeedCard = forwardRef<HTMLDivElement, Props>(
  ({ log, dateSettings, onClick, className }, ref) => {
    const { t } = useTranslate();
    const { formatTime, formatDate } = useDateFormatter(dateSettings);
    const [showFullLog, setShowFullLog] = useState(false);

    function formatDateString(logDate: string) {
      const date = DateTime.fromISO(logDate);
      const today = DateTime.now();
      const yesterday = today.minus({ days: 1 });

      if (date.toISODate() === today.toISODate()) {
        return `${t("relativeTime.today")} ${formatTime(date)}`;
      } else if (date.toISODate() === yesterday.toISODate()) {
        return `${t("relativeTime.yesterday")} ${formatTime(date)}`;
      } else {
        return `${formatDate(date)} ${formatTime(date)}`;
      }
    }

    const handleCardClick = (log: FeedLog) => {
      const id = log.type === "VISIT" ? log.id : log.careRecipientId;
      onClick(id, log.type);
    };

    const toggleShowFullLog = (event: React.MouseEvent) => {
      event.stopPropagation();
      setShowFullLog(!showFullLog);
    };

    return (
      <div ref={ref} className={`max-w-96 flex-col inline-flex`}>
        <div className="h-9 items-center inline-flex">
          <div>
            {log.type === "VISIT" ? (
              <Schedule className="mx-2 text-greyscale-600" />
            ) : (
              <Edit className="mx-2 text-greyscale-600" />
            )}
          </div>
          <div className="ml-3">
            <Paragraph
              size="xs"
              type="secondary"
              className="dark:text-greyscale-300"
            >
              {log.type === "VISIT" ? t("reports.visit") : t("careLog")} -{" "}
              {log.logDate && formatDateString(log.logDate)}
            </Paragraph>
          </div>
        </div>
        <div className="px-4 flex-col flex mr-1 ">
          <div
            className={`pl-3 pt-2 pb-8 border-l dark:border-greyscale-700 border-greyscale-200 inline-flex ${className}`}
          >
            <button
              className="grow shrink basis-0 px-5 pt-4 pb-5 bg-white dark:bg-greyscale-800 rounded-2xl shadow-[0_2px_6px_-1px_rgba(0,0,0,0.1)] flex-col gap-2 hover:shadow-[0_2px_6px_-1px_rgba(0,0,0,0.2)]"
              onClick={() => handleCardClick(log)}
            >
              <div className="gap-1 flex flex-col items-start">
                <Paragraph
                  size="xs"
                  type="secondary"
                  className="dark:text-greyscale-300"
                >
                  {log.fullName}
                  {log.source === "FeedItems" ? " " + t("for") : ""}
                </Paragraph>
                {log.source === "FeedItems" && (
                  <Label
                    size="m"
                    className="cursor-pointer"
                  >{`${log.careRecipientName}`}</Label>
                )}
              </div>
              <div className="pt-4 flex-col items-start flex relative text-start">
                {log.type === "VISIT" && log.visitlogSentiment && (
                  <div className="gap-4 mb-4">
                    <SentimentPill sentiment={log.visitlogSentiment} />
                  </div>
                )}
                <Paragraph size="s" className={`block break-words`}>
                  {log.log
                    ? showFullLog
                      ? log.log
                      : truncateLog(log.log)
                    : t("noVisitNote")}
                  {log.log && shouldTruncate(log.log) && !showFullLog && (
                    <Button
                      size="md"
                      variant="tertiary"
                      className="bg-white text-sm !p-0 rounded-none dark:bg-greyscale-800 focus:outline-none"
                      onClick={toggleShowFullLog}
                      text={t("seeMore")}
                      disabled={false}
                    />
                  )}
                  {log.log && shouldTruncate(log.log) && showFullLog && (
                    <div className="flex justify-end">
                      <Button
                        size="md"
                        variant="tertiary"
                        className="bg-white text-sm !p-0 rounded-none dark:bg-greyscale-800 focus:outline-none"
                        onClick={toggleShowFullLog}
                        text={t("seeLess")}
                        disabled={false}
                      />
                    </div>
                  )}
                </Paragraph>
                <ActivitiesStatusSentence logItem={log} />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  },
);
