import classNames from "classnames";

type Props = {
  firstName?: string;
  lastName?: string;
  img?: string;
  backgroundColor?: string;
  size?: "sm" | "md";
  randomizeColor?: boolean;
};

const validColors = [
  "bg-primary-400", // blue
  "bg-secondary-500", // green
  "bg-accent-400", // yellow
  "bg-critical-400", // red
];

const randomColorBasedOnName = (name: string) => {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = (hash * 31 + name.charCodeAt(i)) & 0xffffffff; // Simple hash function
  }

  const index = Math.abs(hash % validColors.length);
  return validColors[index];
};

export const Avatar = ({
  firstName = "",
  lastName = "",
  img,
  backgroundColor = "bg-primary-90",
  size = "md",
  randomizeColor = false,
}: Props) => {
  const text =
    firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase() ||
    "-";

  const sizeStyles = size === "sm" ? "h-6 w-6" : "h-8 w-8";

  const fontStyles =
    size === "sm"
      ? "text-xs font-normal text-greyscale-900"
      : "text-sm font-medium text-greyscale-900";

  const bg = randomizeColor ? randomColorBasedOnName(text) : backgroundColor;

  return (
    <div
      className={classNames(
        "flex flex-col justify-center items-center overflow-hidden rounded-full  py-2",
        bg,
        sizeStyles,
      )}
    >
      {img ? (
        <img
          src={img}
          alt={text}
          className="h-full w-full rounded-full object-cover"
        />
      ) : (
        <p className={fontStyles}>{text}</p>
      )}
    </div>
  );
};
