import { Label } from "../../typography";
import { DateTime } from "luxon";
import { match } from "ts-pattern";
import { useEffect, useState } from "react";
import { TimePicker } from "../timePicker/TimePicker";
import { DateSettings } from "../../utils/dateUtils";
import { useTranslate } from "@tolgee/react";
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { ErrorMessage } from "../errorMessage/ErrorMessage";
import { ChoiceChips } from "../choiceChips/ChoiceChips";

type Props = {
  timeOptions: number[];
  startTime?: DateTime;
  dateSettings: DateSettings;
  errorMessage?: string;
};

const calculateEndTime = (duration: number, startTime?: DateTime) => {
  return startTime
    ? startTime.plus({ minutes: duration })
    : DateTime.now().plus({ minutes: duration });
};

export function Duration<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  timeOptions,
  startTime,
  dateSettings,
  errorMessage,
  ...props
}: UseControllerProps<TFieldValues, TName> & Props) {
  const { t } = useTranslate();
  const { field } = useController(props);
  const [selectedOther, setSelectedOther] = useState(
    timeOptions.find((time) => time === field.value) === undefined,
  );

  useEffect(() => {
    if (
      timeOptions.find((time) => time === field.value) === undefined &&
      !selectedOther
    ) {
      field.onChange(field.value);
    }
  }, [timeOptions, selectedOther, field]);

  const formatTimeOption = (timeOption: number) => {
    return match(timeOption)
      .with(60, () => {
        return `1 h`;
      })
      .otherwise(() => {
        return `${timeOption} m`;
      });
  };

  const handleDurationChange = (duration: number) => {
    // If the user double taps an option, we should select the "other" option
    if (duration === field.value) {
      setSelectedOther(true);
      return;
    }

    setSelectedOther(false);
    field.onChange(duration);
  };

  const otherChoice = { id: "other", text: t("other") };

  const choices = timeOptions
    .map((time) => {
      return {
        id: time.toString(),
        text: formatTimeOption(time),
      };
    })
    .concat(otherChoice);

  const selectedChoice = !selectedOther
    ? choices.find((choice) => choice.id === field.value.toString())
    : otherChoice;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <Label size="m" htmlFor={"Duration"} className="px-6 py-2">
          {t("duration")}
        </Label>
        <ChoiceChips
          choices={choices}
          selectedChoice={selectedChoice}
          onClick={(choice) => {
            if (choice.id === "other") {
              setSelectedOther(true);
              return;
            }
            handleDurationChange(parseInt(choice.id));
          }}
        />
      </div>
      {selectedOther && (
        <div className="flex flex-col">
          <Label size="m" htmlFor={"Duration"} className="px-6 py-2">
            {t("visitWizard.endTime")}
          </Label>
          <div className="flex-grow">
            <TimePicker
              dateSettings={dateSettings}
              value={calculateEndTime(field.value, startTime)}
              onChange={(val) => {
                if (val) {
                  // We ned to check if the end time is on the next day
                  const startTimeToCheck = startTime ?? DateTime.now();
                  const endsNextDay =
                    val.hour < startTimeToCheck.hour ||
                    (val.hour === startTimeToCheck.hour &&
                      val.minute < startTimeToCheck.minute);

                  const endDateTime = (
                    endsNextDay
                      ? DateTime.now().plus({ day: 1 })
                      : DateTime.now()
                  ).set({
                    hour: val.hour,
                    minute: val.minute,
                  });
                  const duration = endDateTime
                    .diff(startTime ?? DateTime.now())
                    .as("minutes");

                  // round duration to int
                  field.onChange(Math.round(duration));
                }
              }}
              onBlur={field.onBlur}
              errorMessage={errorMessage}
            />
          </div>
        </div>
      )}
      {errorMessage && !selectedOther && (
        <div className="pt-2">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </div>
      )}
    </div>
  );
}
