import {
  Channel as ChannelView,
  Window,
  MessageList,
  MessageInput,
  useChatContext,
  Thread,
  DateSeparatorProps,
} from "stream-chat-react";

import BackIcon from "@frontend/lyng/assets/icons/24/outline/chevron-left.svg?react";
import { useNavigate, useParams } from "react-router";
import { useFeatureFlag } from "../../providers/FeatureFlags";
import { LoadingBounce } from "@frontend/lyng/loading";
import { useDateFormatter } from "@frontend/lyng/utils/dateUtils";
import { useCareContext } from "../../providers";
import { DateTime } from "luxon";
import { MessageSimple } from "@frontend/lyng/comms";
import { Title } from "./Title";
import { useEffect, useState } from "react";
import { Channel } from "stream-chat";
import { calculatePresenceLastTime } from "../../utils/dateUtils";
import { useTranslate } from "@tolgee/react";
import { Button } from "@frontend/lyng/button";

const Loading = () => (
  <div className="flex justify-center dark:bg-greyscale-900 m-4">
    <LoadingBounce size="m" />
  </div>
);

const CustomDateSeparator = (props: DateSeparatorProps) => {
  const {
    state: { viewer },
  } = useCareContext();
  const { formatDate } = useDateFormatter(viewer?.tenantSettings);

  const dateTime = DateTime.fromJSDate(props.date);

  return (
    <div className="flex justify-center items-center py-2">
      <div className="px-3 py-px rounded-lg justify-start items-center gap-2.5 inline-flex">
        <div className="text-center text-xs font-normal font-inclusive leading-none">
          {formatDate(dateTime)}
        </div>
      </div>
    </div>
  );
};

export const Chat = () => {
  const { id } = useParams();
  const { t } = useTranslate();
  const { client } = useChatContext();
  const navigate = useNavigate();
  const ff_messaging = useFeatureFlag("Messaging");
  const [userPresence, setUserPresence] = useState("Offline");
  let channel: Channel | null = null;

  useEffect(() => {
    const fetchPresence = async () => {
      if (!channel) return;

      const state = await channel.watch({ presence: true });
      const lastActive = state.members.find(
        (member) => member.user_id !== client.userID,
      )?.user?.last_active;

      const presence = calculatePresenceLastTime(lastActive, t);

      setUserPresence(presence);
    };
    fetchPresence();
  }, [channel, client.userID, t]);

  if (!ff_messaging) {
    navigate("/");
    return <Loading />;
  }

  if (!id || !client.wsConnection) {
    return <Loading />;
  }

  channel = client.channel("messaging", id);

  const otherMembers = Object.values(channel.state.members).filter(
    (member) => member.user_id !== client.userID,
  );
  // if othermembers is just 1 then channel name is that user name, otherwise channel name is ({user1}, {user2}, {user3}, etc...)
  const channelName =
    otherMembers.length === 1 ? otherMembers[0].user?.name : channel.data?.name;

  return (
    <div className="h-screen overflow-y-clip">
      <div className="absolute top-0 pl-2 py-8 z-20 w-full">
        <div className="flex justify-start flex-row gap-2">
          <Button
            variant="secondary"
            onClick={() => {
              navigate("..");
              return;
            }}
            icon={BackIcon}
            iconPosition="only"
          />
          <Title title={channelName ?? "Chat"} subtitle={userPresence} />
        </div>
      </div>
      <ChannelView channel={channel} DateSeparator={CustomDateSeparator}>
        <Window>
          <div className="justify-center h-screen dark:bg-greyscale-900 pt-24 pb-12">
            <MessageList Message={MessageSimple} />
          </div>
          <div className="sticky bottom-0">
            <MessageInput />
          </div>
          <Thread />
        </Window>
      </ChannelView>
    </div>
  );
};
